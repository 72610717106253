<!-- 客户产能/工时数据看板 -->
<template>
    <div v-loading="loading" style=" height: calc(100% - 50px);">
        <div class="title">客户产能/工时数据看板</div>
        <div class="box">
            <div class="block">
                <el-date-picker v-model="time_of_date" type="month" format="YYYY-MM" @change="datePickerfn"
                    placeholder="请选择月份" />
            </div>
            <div class="block-select">
                <el-select v-model="custom_name" 
                    :remote-method="(query) => remoteMethod(query, 'search')" clearable filterable remote placeholder="客户名称"
                    v-loadmore="loadMoreSelect('search')" @focus="initSelect('search')" :loading="state.search.loading"
                    @clear="clears"
                    @change="changeDropDown('search')">
                    <template #prefix>
                        <el-icon>
                            <Search />
                        </el-icon>
                    </template>
                    <el-option v-for="item in customerList" :key="item.id" :label="item.customer_short_name" :value="item.id" />
                </el-select>
            </div>
            <!-- 工位编号/名称 -->
            <!-- vue自定义指令v-load-more实现上拉加载 @load-more -->
            <!--  @load-more="loadMoreSelect('number')" -->
            <div class="block-select">
                <el-select v-model="station_number" placeholder="工位编号/名称" remote-show-suffix
                    :remote-method="(query) => remoteMethod(query, 'number')" clearable filterable remote
                    v-loadmore="loadMoreSelect('number')" @focus="initSelect('number')" :loading="state.number.loading"
                    :disabled="custom_name!==''?false:true"
                    @change="changeDropDown('number')">
                    <li class="el-cascader-node" style="height:22px;line-height: 22px" ref="liRef">
                        <span class="el-cascader-node__label">
                            <div class="affix-box">
                                <div class="display_flex">
                                    <span v-for="item in multiColumnTitle" :key="item.id">{{ item }}</span>
                                </div>
                            </div>
                        </span>
                    </li>
                    <el-option v-for="item in msgTypes" :key="item.id" :label="item.work_station_name" :value="item.id">
                        <template #default>
                            <span style="float: left;margin-right: 20px;">{{ item.work_station_no }}</span>
                            <span style="float: right;font-size: 13px;">{{ item.work_station_name }}</span>
                        </template>
                    </el-option>

                </el-select>
            </div>

        </div>
        <!-- :style="{ width: '100%', height: '80%', overflow: 'hidden' }" -->
        <div @click="sizechange" ref="MyChartDom" class="chart"></div>
        <div class="total">

            <div>总工时：<span>{{ man_hour }}</span></div>
            <div>总产能：<span>{{ capacity }}</span></div>
            <div>工位数：<span>{{ workstation }}</span></div>
        </div>
    </div>
    <span v-show="isShow !== '1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import * as echarts from "echarts";
import { month_actual, customer_manage, getWorkStationList } from "@/api/ChartsDataRequest";
import dayjs from 'dayjs'

export default {
    name: "customer_status_profile",
    props: ["xref", "type", "chartData"],
    emits: ["closeChart"],
    data() {
        return {
            option: {},
            loading: true,
            isShow: '',
            man_hour: '1000',//总工时
            capacity: '',//总产能
            workstation: '',//工位数
            time_of_date: dayjs().format('YYYY-MM'),//月份
            custom_name: '',//客户名称
            station_number: '',//工位编号
            customerList: [],
            msgTypes: [],
            state: {
                search: {
                    page: 1,
                    listCount: 1,
                    page_rows: 10000,
                    customer_status: '',
                    loading: false,
                    search: ''
                },
                number: {
                    page: 1,
                    listCount: 1,
                    page_rows: 10000,
                    loading: false,
                    produce_date: dayjs().format('YYYY-MM'),
                    search: ''
                }
            },
            multiColumnTitle: ['工位编号', '工位名称'],
        };
    },
    methods: {
        // 月份选择
        datePickerfn(day) {
            if (day) {
                this.time_of_date = dayjs(day).format('YYYY-MM')
                this.state.number.produce_date = dayjs(day).format('YYYY-MM')
                
            } else {
                this.time_of_date = dayjs().format('YYYY-MM')
                this.state.number.produce_date = dayjs().format('YYYY-MM')
            }
            this.createChart()
        },

        // 模糊搜索
        remoteMethod(query, type) {
            if (query) {
                this.state.search[type] = query
                if (type === 'number') this.state[type].search = query
            } else {
                this.state.search[type] = ''
                if (type === 'number') this.state[type].search = query
            }
            this.initSelect(type)

        },
        async initSelect(type) {
            let res = null
            let datalist = []
            if (type === 'search') {
                res = await customer_manage(this.state[type]).finally(() => {
                    this.state[type].loading = false
                })
                datalist = res.data.data.list
                if (this.state[type].page === 1) {
                    this.customerList = []
                }
                this.customerList = this.customerList.concat(datalist)
            } 
            else if (type === 'number') {
                    res = await getWorkStationList({
                        ...this.state[type],
                        customer_id:this.custom_name,
                    }).finally(() => {
                        this.state[type].loading = false
                    })
                    datalist = res.data.data.list
                    if (this.state[type].page === 1) {
                        this.msgTypes = []
                    }
                    this.msgTypes = this.msgTypes.concat(datalist)
            }
            this.state[type].listCount = Math.ceil(res.data.data.total / res.data.data.page_rows)
        },

        loadMoreSelect(type) {
            if (this.state[type].page < this.state[type].listCount) {
                this.state[type].page++
                this.initSelect(type)
            }
        },
        // 选中
        changeDropDown(type) {
            if(type==='search'){
                this.station_number=''
            }
            this.createChart()
        },
        clears(){
            this.station_number=''
        },

        // 图表关闭
        handleCloseChart() {
            this.$emit("closeChart");
        },
        getEqualNewlineString(params, length) {
            if (!params) return "";
            if (params.length > length) {
                //10是截取的字符串长度，根据你的情况调整数值
                params = params.slice(0, length) + "...";
            }
            return params;
        },

        // 图表创建
        async createChart() {
            this.ChartDom = this.$refs.MyChartDom;
            this.MyChart = echarts.init(this.ChartDom, null);
            let maxNumber = ''
            const res = await month_actual({
                time_of_date: this.time_of_date,//日期
                customer_id: this.custom_name, //客户id
                work_station_id: this.station_number// 工位id
            })
            if (res.data.code === 200) {
                this.man_hour = res.data.data.total_hour
                this.capacity = res.data.data.total_actual
                this.workstation = res.data.data.work_station_num
                maxNumber = Math.max(...res?.data?.data.yData[1].data);
                // maxNumber=  res?.data?.data.yData[1].data.reduce((acc, curr) => acc + curr, 0)
                const nres = {
                    xData: res?.data?.data.xData,
                    yData: res?.data?.data.yData,
                };
                const tData = nres.xData.map(item => item.slice(-2))
                const xData1 = nres.yData.find(
                    (item) => item.name === "工时"
                ).data;
                const xData2 = nres.yData.find(
                    (item) => item.name === "产能"
                ).data;
                const option = {
                    // title: {
                    //     text: "",
                    //     left: "center",
                    //     textStyle: {
                    //         color: "#fff",
                    //         fontWeight: "normal",
                    //         fontSize: "18px",
                    //     },
                    // },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'line',//阴影指示器
                        },
                    },
                    // 底部柱状
                    legend: {
                        bottom: 0,
                        textStyle: {
                            color: "#fff",
                            fontSize: "13px",
                            padding: [0, 0, 0, 0],
                        },
                    },
                    grid: {
                        top: 50,
                        left: 20,
                        right: 20,
                        bottom: 60
                    },
                    xAxis: [
                        {
                            type: 'category',
                            axisLabel: {
                                color: "#fff", // 更改坐标轴文字颜色
                                fontSize: 14, // 更改坐标轴文字大小
                            },
                            data: tData,
                        }
                    ],
                    yAxis: [
                        {
                            // name:'工时(H)'
                            type: 'value',
                            position: 'left',
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    color: ['#546f6d']
                                }
                            },
                            // y轴左侧文字颜色
                            axisLine: {
                                onZero: false,
                                show: false,
                                lineStyle: {
                                    color: '#fff'
                                }
                            },
                            // y轴左侧文字大小
                            axisLabel: {
                                margin: 10,
                                fontSize: 12,
                                formatter: () => {
                                    return ''
                                }
                            }
                        },
                        {
                            // name: '产能(PCS)',
                            type: 'value',
                            position: 'right',
                            max: maxNumber,
                            show: false,
                            // 设置刻度线的颜色等样式
                            axisLine: {
                                onZero: false,
                                lineStyle: {
                                    color: '#fff'
                                }
                            },
                            splitLine: {
                                show: false // 想要不显示网格线，改为false
                            }

                        }
                    ],
                    // 滑动
                    dataZoom: [
                        {
                            type: 'inside',
                            show: true,
                            xAxisIndex: [0],
                            start: 1,
                            end: 100,
                            // 最大的放大是图形的2%
                            minSpan: 50
                        }
                    ],
                    series: [
                        {
                            name: '工时(H)',
                            type: 'bar',
                            itemStyle: {
                                color: '#26c218'

                            },
                            label: {
                                show: true,
                                position: 'top',
                                verticalAlign: 'top',
                                rotate: 50,
                                align: 'right',
                                offset: [10, -12], // 偏移[20, -5]
                                // offset: [1, 0],//偏移
                                distance: 10,
                                color: '#fff',
                                padding: [0, 0, 0, 0],
                                formatter: (e) => {
                                    if (parseFloat(e.data)) {
                                        return e.data
                                    }
                                    return ''
                                }
                            },
                            yAxisIndex: 0,
                            data: xData1
                        },
                        {
                            name: '产能(PCS)',
                            type: 'bar',
                            // barGap:1,柱子间间隙
                            itemStyle: {
                                color: '#f6b509'
                            },
                            label: {
                                show: true,
                                position: 'top',
                                verticalAlign: 'top',
                                rotate: 50,
                                align: 'left',
                                offset: [-6,-1], // 偏移[-15,-6]
                                // offset: [10, 7],//偏移
                                distance: 10,
                                color: '#fff',
                                formatter: (e) => {
                                    if (parseFloat(e.data)) {
                                        return e.data
                                    }
                                    return ''
                                }
                            },
                            yAxisIndex: 1,
                            data: xData2
                        }
                    ]
                }
                this.MyChart.setOption(option);
                this.loading = false;
            }

            setTimeout(() => {
                this.$nextTick(() => {
                    this.MyChart.resize();
                });
            }, 0);
        },
        sizechange() {
            this.MyChart.resize();
        },
    },
    mounted() {
        this.createChart();
        this.isShow = sessionStorage.getItem("isShow")
    },
};
</script>

<style scoped lang="scss">
.chart {
    width: 100%;
    padding-top: 10px;
    height: calc(100% - 60px)
}

.title {
    margin-top: 10px;
    font-size: 18px;
    color: #fff;
}

.box {
    display: flex;
    justify-content: flex-start;
    margin-left: 30px;

    .block {
        margin-right: 10px;

        :deep(.el-input__inner) {
            margin: 0;
        }

        :deep(.el-date-editor.el-input) {
            width: 180px;
            border: 1px solid #fff;
            border-radius: 30px;
        }

        :deep(.el-input__wrapper) {
            border-radius: 30px;
        }

        :deep(.el-input__inner) {
            color: #fff;
            font-size: 16px;
        }
    }

    .block-select {
        margin-right: 10px;

        :deep(.el-select) {
            width: 180px;
            --el-select-border-color-hover: #fff;
            --el-select-input-focus-border-color: #fff;

            .el-input__inner {
                font-size: 16px;
            }
        }

        :deep(.el-input__wrapper) {
            border-radius: 30px;
        }

    }
}

.total {
    // margin-bottom: 5px;
    display: flex;
    justify-content: center;

    div {
        margin: 0 10px;
        color: #fff;

        span {
            color: #FFC70A;
        }
    }
}

:deep(.el-date-editor .el-icon) {
    display: block !important;
}

.display_flex {
    display: flex;
    justify-content: space-between;
    color: #000;
    font-size: 14px;
}

.vue-chart-setting {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 2px;
    right: 24px;
    background: url("../../assets/settings.svg");
    background-size: 16px 16px;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-origin: content-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

.vue-chart-close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 2px;
    right: 2px;
    background: url("../../assets/x.svg");
    background-size: 18px 18px;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-origin: content-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}
</style>


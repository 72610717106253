// 构造装载图表的盒子模型数据
const chartsBoxes = 
    [
        //每一个 grid-item 的属性
        { x: 0, y: 0, w: 4, h: 6, i: 0, static: false , chartBoxKey:'NewCustormer'},
        { x: 4, y: 0, w: 3, h: 6, i: 1, static: false , chartBoxKey:'NewCustormer1'},
        { x: 7, y: 0, w: 3, h: 6, i: 2, static: false , chartBoxKey:'NewCustormer2'},
    ] 

// 构造图表所用的数据结构
const chartsData = [
    {
        chartId:0,
        title:"混合用法",
        type: '',
        data: {
            tooltip: {
                trigger: "item",
            },
            xAxis: {
                type: "category",
                data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            },
            yAxis: {
                type: "value",
            },
            series:[
                {
                    data: [150, 230, 224, 218, 135, 147, 260], // 纵坐标值
                    type: "line", // 图表类型line bar pie
                },
                {
                    data: [110, 130, 124, 250, 135, 247, 360], // 纵坐标值
                    type: "line", // 图表类型line bar pie
                },
            ],
        },
     },
    {
        chartId:1,
        type: 'pie',
        title:"饼状图",
        data: [
            { value: 1048, name: "人事部" },
            { value: 735, name: "技术部" },
            { value: 580, name: "财务部" },
            { value: 484, name: "生产部" },
            { value: 300, name: "业务部" },
        ]
    },
    {
    chartId:2,
    title:"线状图",
    type: 'line',
    data: {
        tooltip: {
            trigger: "axis",
            axisPointer: [],
            
        },
        xAxis: {
            type: "category",
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
            type: "value",
        },
        series:[
            {
                smooth: true,
                symbol: 'circle',
                data: [150, 230, 224, 218, 135, 147, 260], // 纵坐标值
                type: "line", // 图表类型line bar pie
            },
            {
                smooth: true,
                symbol: 'circle', // 实心点
                data: [50, 130, 324, 318, 335, 187, 280], // 纵坐标值
                type: "line", // 图表类型line bar pie
            },
        ],
    },
 },
 {
    chartId:3,
    title:"柱状图",
    type: 'bar',
    data: {
        tooltip: {
            trigger: "item",
        },
        xAxis: {
            type: "category",
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
            type: "value",
        },
        series: 
            [
                {
                    data: [50, 230, 124, 250, 635, 247, 360], // 纵坐标值
                    type: "line", // 图表类型line bar pie
                },{
                    data: [100, 280, 184, 250, 435, 347, 460], // 纵坐标值
                    type: "bar", // 图表类型line bar pie
                },
            ],
    },
    
 },
 {
    chartId:4,
    title:"柱状图",
    type: 'line',
    data: {
        tooltip: {
            trigger: "item",
        },
        xAxis: {
            type: "category",
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
                    name: "销售额",
                    type: "value",
                    position: "left",
                },
               
        series: 
            [
                {
                    data: [50, 230, 124, 250, 635, 247, 360], // 纵坐标值
                    type: "line", // 图表类型line bar pie
                    smooth: true,
                   
                },
                {
                    data: [100, 280, 184, 250, 435, 347, 460], // 纵坐标值
                    type: "line", // 图表类型line bar pie
                    smooth: true,
                    
                },
                {
                    data: [230, 180, 184, 200, 335, 247, 360], // 纵坐标值
                    type: "line", // 图表类型line bar pie
                    smooth: true,
                    
                },
            ],
    },
    
 },

]

exports.chartsData = chartsData;
exports.chartsBoxes = chartsBoxes;



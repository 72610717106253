<!-- 机器人-本月故障次数 -->
<template>
	<div
		v-loading="loading"
		:style="{ width: '100%', height: '100%', overflow: 'hidden' }"
	>
		<div class="today_work_hours_pie">
			<div>{{value}}</div>
			<div>{{name}}</div>
		</div>
	</div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import Progress from "./todayWorkHour.js";
let progressDom = null;
let progressData = {};
export default {
	name: "customer_status_profile",
	props: ["xref", "type", "chartData",'value','name'],
	emits: ["closeChart"],
	data() {
		return {
			loading: false,
      waitTime:0,
      dayRate:'',
			isShow:''
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},

		// 图表创建
		createChart() {
			progressDom = new Progress(progressData);
			progressDom.init();
		},
		sizechange() {
			progressDom.remove();
			setTimeout(() => {
				progressDom = new Progress(progressData);
				progressDom.init();
			}, 200);
		},
	},
	async mounted() {


		setTimeout(() => {
			this.createChart();
		}, 800);
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style>
.vue-chart-setting {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 24px;
	background: url("../../assets/settings.svg");
	background-size: 16px 16px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}
.vue-chart-close {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 2px;
	background: url("../../assets/x.svg");
	background-size: 18px 18px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}

.today_work_hours_pie{
	display: flex;
	justify-content: space-around;
}
.today_work_hours_pie > div:first-child{
	font-size:36px;
	color:#FFBA21;
}
.today_work_hours_pie > div:last-child{
	font-size:20px;
	color:#fff;
}
</style>


<template>
	<div v-loading="loading" @click="sizechange" ref="MyChartDom" class="emergency_warning">
		<div class="arrow">
			<el-icon color="#fff" :size="20" @click="move('left')">
				<ArrowLeftBold />
			</el-icon>
		</div>
		<div class="arrow arrow-right" >
			<el-icon color="#fff" :size="20" @click="move('right')">
				<ArrowRightBold />
			</el-icon>
		</div>
		<div class="warning_title">紧急预警</div>
		<div class="item-wrap" :style="{left: num+'px'}">
			<div class="item-row" v-for="(item,i) in list" :key="i">
				<div class="item">
					<div class="svg">
						<svg viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clip-path="url(#clip0_1425_211745)">
								<mask id="path-1-inside-1_1425_211745" fill="white">
									<rect x="17.6211" y="27.3906" width="18.7595" height="7.03481" rx="0.468987" />
								</mask>
								<rect x="17.6211" y="27.3906" width="18.7595" height="7.03481" rx="0.468987" stroke="#FF6464"
									stroke-width="2.31429" mask="url(#path-1-inside-1_1425_211745)" />
								<path
									d="M30.0505 37.2773C30.3096 37.2773 30.5195 37.4873 30.5195 37.7463L30.5195 46.1881C30.5195 46.4471 30.3096 46.6571 30.0505 46.6571L23.9537 46.6571C23.6947 46.6571 23.4847 46.4471 23.4847 46.1881L23.4847 37.7463C23.4847 37.4873 23.6947 37.2773 23.9537 37.2773L30.0505 37.2773Z"
									fill="#FF6464" />
								<path
									d="M30.048 9.42223H34.4162H35.9942C36.1755 9.42223 36.3224 9.2753 36.3224 9.09407V6.46879C36.3224 6.28755 36.1755 6.14062 35.9942 6.14062H29.5312"
									stroke="#FF6464" stroke-width="1.03517" />
								<path
									d="M23.8651 12.8517C23.8651 14.4168 22.5963 15.6856 21.0312 15.6856C19.4661 15.6856 18.1973 14.4168 18.1973 12.8517C18.1973 11.2865 19.4661 10.0177 21.0312 10.0177C22.5963 10.0177 23.8651 11.2865 23.8651 12.8517Z"
									stroke="#FF6464" stroke-width="1.03517" />
								<circle cx="21.0308" cy="12.8511" r="0.574693" transform="rotate(180 21.0308 12.8511)" fill="#FF6464" />
								<path d="M34.9242 16.1539L36.1367 14.3351L34.9242 11.9102L32.4992 11.9102L31.2867 14.3351L32.4992 16.1539"
									stroke="#FF6464" stroke-width="1.13869" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M33.7656 10.043L33.7656 11.5725" stroke="#FF6464" stroke-width="1.13869" stroke-linecap="square"
									stroke-linejoin="round" />
								<path d="M24.7461 23.4062L24.7461 20.2468" stroke="#FF6464" stroke-width="1.15714" stroke-linecap="round"
									stroke-linejoin="round" />
								<path d="M29.0039 23.9648L29.0039 18.7412" stroke="#FF6464" stroke-width="1.15714" stroke-linecap="round"
									stroke-linejoin="round" />
								<path
									d="M21.7568 9.94762L28.0065 6.33939C28.9327 5.80462 30.1171 6.12198 30.6519 7.04823V7.04823C31.1949 7.98879 30.8585 9.19194 29.9063 9.71436L24.209 12.8403"
									stroke="#FF6464" stroke-width="1.03517" stroke-linecap="round" stroke-linejoin="round" />
								<path
									d="M23.9159 12.906L28.3539 17.3338C29.1967 18.1748 29.2197 19.533 28.4058 20.402V20.402C27.55 21.3158 26.106 21.3345 25.2267 20.4432L20.4808 15.6321"
									stroke="#FF6464" stroke-width="1.03517" stroke-linecap="round" stroke-linejoin="round" />
								<path
									d="M17.3377 24.0214H36.6532C37.4073 24.0214 38.0894 24.4694 38.3891 25.1614L40.1379 29.2003C40.2407 29.4376 40.2937 29.6934 40.2937 29.9519V44.0364C40.2937 45.0811 39.4468 45.928 38.4021 45.928H15.5888C14.5441 45.928 13.6972 45.0811 13.6972 44.0364V29.9519C13.6972 29.6934 13.7502 29.4376 13.853 29.2003L15.6018 25.1614C15.9015 24.4694 16.5835 24.0214 17.3377 24.0214Z"
									stroke="#FF6464" stroke-width="1.54286" />
								<path
									d="M12.9258 42.6562H14.4645V47.8577C14.4645 48.2826 14.12 48.6271 13.6951 48.6271V48.6271C13.2702 48.6271 12.9258 48.2826 12.9258 47.8577V42.6562Z"
									fill="#FF6464" />
								<path
									d="M39.5352 42.6562H41.0748V47.8573C41.0748 48.2824 40.7301 48.6271 40.305 48.6271V48.6271C39.8798 48.6271 39.5352 48.2824 39.5352 47.8573V42.6562Z"
									fill="#FF6464" />
							</g>
							<defs>
								<clipPath id="clip0_1425_211745">
									<rect width="54" height="54" fill="white" />
								</clipPath>
							</defs>
						</svg>
					</div>
					<div class="warning-text">
						<div>{{item.text}}</div>
						<div>{{item.num}}%</div>
						<div>{{item.name}}</div>
					</div>
				</div>
			</div>
		</div>

	</div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import { ElMessage } from 'element-plus'
import { ArrowRightBold, ArrowLeftBold } from "@element-plus/icons-vue";
export default {
	name: "ranked_big",
	props: ["xref", "type", "chartData"],
	emits: ["closeChart"],
	data() {
		return {
			option: {},
			loading: true,
			num:0,
			current:1,
			rowNum:3,
			list:[
				{text:'稼动率异常',num:'50',name:'爱沛柯尔1'},
				{text:'稼动率异常',num:'60',name:'爱沛柯尔2'},
				{text:'稼动率异常',num:'70',name:'爱沛柯尔3'},
				{text:'稼动率异常',num:'80',name:'爱沛柯尔4'},
				{text:'稼动率异常',num:'90',name:'爱沛柯尔5'},
				{text:'稼动率异常',num:'55.6',name:'爱沛柯尔6'},
				{text:'稼动率异常',num:'66.9',name:'爱沛柯尔7'},
				{text:'稼动率异常',num:'99.9',name:'爱沛柯尔8'},
				{text:'稼动率异常',num:'99.9',name:'爱沛柯尔8'},
			],
			isShow:''
		};
	},
	components: {
		ArrowRightBold, ArrowLeftBold
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},
		move(type){
			const w=this.$refs['MyChartDom'].offsetWidth
			const total=Math.ceil(this.list.length/this.rowNum)
			// console.log(total,'total',this.list.length/this.rowNum);
			if(type==='left'){
				console.log(this.current,total);
				if(this.current===1){
					ElMessage.success('已是第一个')
					return false
				}
				
				this.current--
				
				this.num='-'+(this.current-1)*w
			
			}else{
				if(this.current>=total){
					ElMessage.success('已是最后一个')
					return false
				}
				
				this.current++
				
				this.num='-'+(this.current-1)*w

			}
			
			console.log(type,this.current,this.num);
		},
	},
	mounted() {
		this.loading = false
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style  lang="scss">
.vue-resizable-handle{
	z-index: 11;
}
.vue-chart-setting {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 24px;
	background: url("../../assets/settings.svg");
	background-size: 16px 16px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}

.vue-chart-close {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 2px;
	background: url("../../assets/x.svg");
	background-size: 18px 18px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}

.el-row {
	width: 100%;
}

.emergency_warning {
	overflow: hidden;
	width: 100%;
	height: 100%;

	// display: flex;
	.arrow {
		position: absolute;
		left: 0;
		height: 80%;
		bottom: 0;
		display: flex;
		align-items: center;
		z-index: 10;
		cursor: pointer;
	}

	.arrow-right {
		left: auto;
		right: 0;
	}

	.warning_title {
		font-size: 20px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 20%;
		background-color: rgba(255, 255, 255, 0.08);
		color: #fff;
		
	}

	.item-wrap {
		
		height: 80%;
		display: flex;
		align-items: center;
		transition:all 0.1s ease-in-out;
		position: relative;
		.item-row {
			flex-basis: 33.33%;
			flex-shrink: 0;
			width: 33.33%;
			
			.item {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				.svg {
					width: 25%;
					// height: 0;
					// padding-bottom: 100%;
					background-color: #262421;
					border-radius: 10px;

					svg {
						// width: 100%;
						// height: 100%;
					}
				}

			}
		}


	}



	.el-col-6 {
		height: 100% !important;
	}

	.warning-text {
		flex-direction: column;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 14px;
		padding-left: 20px;

		>div:nth-child(1) {
			color: #FF6464;
		}

		>div:nth-child(2) {
			padding: 8px 0;
		}


	}

}
</style>

<!-- 本月客户工时分析 -->
<template>
	<div
		@click="sizechange"
		ref="MyChartDom"
		v-loading="loading"
		:style="{ width: '100%', height: '100%', overflow: 'hidden' }"
	></div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import * as echarts from "echarts";
import { apiGetAnalysisWorkingTime } from "@/api/ChartsDataRequest";

export default {
	name: "customer_status_profile",
	props: ["xref", "type", "chartData"],
	emits: ["closeChart"],
	data() {
		return {
			option: {},
			loading: true,
			isShow:''
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},

		// 图表创建
		createChart() {
			this.ChartDom = this.$refs.MyChartDom;
			this.MyChart = echarts.init(this.ChartDom, null);
			apiGetAnalysisWorkingTime().then((res) => {
				this.loading = false;
				const showData = res?.data?.data;
				const customer_list = showData.xData;
				const option = {
					title: {
						text: "本月客户工时分析",
						left: "center",
						top: "2%",
						textStyle: {
							color: "#fff",
							fontWeight: "normal",
							fontSize: "18px",
						},
					},
					legend: {
						textStyle: {
							color: "#fff",
						},
						itemStyle: {
							borderWidth: 1,
						},
						bottom: 10,
						data: [
							{
								name: "本月标准工时",
								icon: "path://M789.333333 170.666667a64 64 0 0 1 64 64v554.666666a64 64 0 0 1-64 64H234.666667a64 64 0 0 1-64-64V234.666667a64 64 0 0 1 64-64h554.666666z m-554.666666 64v554.666666h554.666666V234.666667H234.666667z",
								itemStyle: {
									color: "rgb(164, 195, 220)",
								},
							},
							{
								name: "本月实际工时",
								icon: "path://M810.666667 128H213.333333a85.333333 85.333333 0 0 0-85.333333 85.333333v597.333334a85.333333 85.333333 0 0 0 85.333333 85.333333h597.333334a85.333333 85.333333 0 0 0 85.333333-85.333333V213.333333a85.333333 85.333333 0 0 0-85.333333-85.333333z",
							},
							{
								name: "本月平均工时",
								icon: "path://M30.6983 4.13281H0.164062V0.132812H30.6983V4.13281Z",
							},
							{
								name: "上月同期平均工时",
								icon: "path://M4.52772 4.13281H0.210938V0.132812H3.52772V4.13281ZM16.7948 4.13281H10.1613V0.132812H16.7948V4.13281ZM26.7452 4.13281H23.4284V0.132812H26.7452V4.13281Z",
							},
						],
					},
					grid: {
						left: 16,
						right: 20,
						bottom: 24,
						top: 60,
						containLabel: true,
					},
					tooltip: {
						trigger: "axis",
						textStyle: {
							align: "left",
						},
						axisPointer: {
							// 坐标轴指示器，坐标轴触发有效，
							type: "shadow", // 默认为line，line直线，cross十字准星，shadow阴影
							crossStyle: {
								color: "#fff",
							},
						},
						formatter: function (params) {
							const nullTxt = "\xa0" + "\xa0" + "\xa0" + "\xa0";
							let relVal = params[0].name;
							for (let i = 0, l = params.length; i < l; i++) {
								if (params[i].seriesName.startsWith("本月平均工时")) {
									//params[i].value = params[i].value;
									relVal +=
										'<div style="width:180px"><span style="display: inline-block;width: 10px;border: 2px solid #FFBA21;margin-bottom:4px;margin-right:5px"></span>' +
										params[i].seriesName +
										nullTxt +
										'<span style="text-align:right;float:right">' +
										params[i].value +
										"h" +
										"</span>" +
										"</div>";
								} else if (params[i].seriesName === "本月标准工时") {
									//params[i].value = params[i].value;
									relVal +=
										'<div style="width:180px"><span style="display: inline-block;width: 9px;height: 9px;border: 2px solid #bad7f2;margin-right:5px"></span>' +
										params[i].seriesName +
										nullTxt +
										'<span style="text-align:right;float:right">' +
										params[i].value +
										"h" +
										"</span>" +
										"</div>";
								} else if (params[i].seriesName === "本月实际工时") {
									//params[i].value = params[i].value;上月同期工时
									relVal +=
										'<div style="width:180px"><span style="display: inline-block;width: 12px;height: 12px;background:#bad7f2;margin-right:5px"></span>' +
										params[i].seriesName +
										nullTxt +
										'<span style="text-align:right;float:right">' +
										params[i].value +
										"h" +
										"</span>" +
										"</div>";
								} else  {
									//params[i].value = params[i].value;
									relVal +=
										'<div style="width:180px"><span style="display: inline-block;width: 10px;border: 2px dashed #d46464;margin-bottom:4px;margin-right:5px"></span>' +
										params[i].seriesName +
										nullTxt +
										'<span style="text-align:right;float:right">' +
										params[i].value +
										"h" +
										"</span>" +
										"</div>";
								}
							}
							return relVal;
						},
					},
					xAxis: {
						type: "category",
						data: customer_list,
						axisLabel: {
							color: "#fff", // 更改坐标轴文字颜色
							fontSize: 14, // 更改坐标轴文字大小
							rotate: -30,
						},
					},
					yAxis: [
						{
							type: "value",
							axisLabel: {
								color: "#fff", // 更改坐标轴文字颜色
								fontSize: 14, // 更改坐标轴文字大小
							},
							splitLine: {
								// 网格线
								lineStyle: {
									// type: 'dashed', // 虚线
									color: "#4F6462",
								},
							},
						},
						{
							type: "value",
							axisLabel: {
								color: "#fff", // 更改坐标轴文字颜色
								fontSize: 14, // 更改坐标轴文字大小
								formatter: "{value} ", // 纵坐标百分比
							},
							splitLine: {
								// 网格线
								lineStyle: {
									// type: 'dashed', // 虚线
									color: "#4F6462",
								},
							},
						},
					],
					series: [
						{
							name: showData.yData[0].name,//本月标准工时
							type: "bar",
							data: showData.yData[0].data,
							itemStyle: {
								borderWidth: 2,
								borderColor: "rgb(164, 195, 220)",
								color: "rgb(20, 50, 48)",
							},
							barMaxWidth: 30,
						},
						{
							name: showData.yData[1].name,//本月实际工时
							type: "bar",
							data: showData.yData[1].data,
							itemStyle: {
								color: "rgb(164, 195, 220)",
							},
							barMaxWidth: 30,
						},
						{
							name: showData.yData[2].name, //本月平均工时
							type: "line",
							showSymbol: false, // 中间空心点
							itemStyle: {
								color:'#FFC824'
							},
							emphasis: {
								focus: "series",
							},
							data: showData.yData[2].data,
						},
						{
							name: showData.yData[3].name,//上月同期平均工时
							type: "line",
							yAxisIndex: 1,
							showSymbol: false, // 中间空心点
							itemStyle: {
								color: "#D46464",
							},
							emphasis: {
								focus: "series",
							},
							lineStyle: {
								type: "dashed",
							},
							data: showData.yData[3].data,
						},
					],
				};
				this.MyChart.setOption(option);
				this.loading = false;
			});

			setTimeout(() => {
				this.$nextTick(() => {
					this.MyChart.resize();
				});
			}, 0);
		},
		sizechange() {
			this.MyChart.resize();
		},
	},
	mounted() {
		this.createChart();
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style>
.vue-chart-setting {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 24px;
	background: url("../../assets/settings.svg");
	background-size: 16px 16px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}

.vue-chart-close {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 2px;
	background: url("../../assets/x.svg");
	background-size: 18px 18px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}
</style>

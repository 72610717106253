<!--12个月部署机器人数量变化-->
<template>
	<div
		@click="sizechange"
		ref="MyChartDom"
		:style="{ width: '100%', height: '100%', overflow: 'hidden' }"
	></div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import * as echarts from "echarts";
import { apiGetRobotNumChange } from "@/api/ChartsDataRequest";

const chartTitle = {
	left: "left",
	textStyle: {
		fontSize: 14,
	},
};
export default {
	name: "custormer_default",
	props: ["xref", "type", "chartData"],
	emits: ["closeChart", "settingChart"],
	data() {
		return {
			option: {
				// 图表标准件
				title: {
					text: "Defaut Name", // 标题
					...chartTitle,
				},
				tooltip: {
					trigger: "axis",
					axisPointer: {
						type: "cross",
					},
				},
				xAxis: {
					type: "category",
				},
				yAxis: {
					type: "value",
				},
				series: [],
			},
			chartBoxSetting: {
				type: "bar",
				name: "", // chart 标题
				xdata: [],
				series: [],
			},
			isShow:''
		};
	},
	methods: {
		handleCloseChart() {
			this.$emit("closeChart");
		},
		handleSettingChart() {
			this.$emit("settingChart", this.chartData.i);
		},
		CreateChart() {
			this.ChartDom = this.$refs.MyChartDom;
			this.MyChart = echarts.init(this.ChartDom);

			apiGetRobotNumChange().then((res) => {
				if (res.data.code == 200) {
					this.MyChart.setOption({
						title: {
							text: "12个月部署机器人数量变化",
							left: "center",
							top: "3%",
							textStyle: {
								color: "#fff",
								fontWeight: "normal",
								fontSize: "18px",
							},
						},

						legend: {
							data: [
								res?.data?.data?.yData[0].name,
								res?.data?.data?.yData[1].name,
							],
							bottom: "3%",
							textStyle: {
								color: "#fff",
							},
							width: "80%",
						},
						tooltip: {
							trigger: "item",
							axisPointer: {
								type: "cross",
								label: {
									backgroundColor: "#6a7985",
								},
							},
						},
						grid: {
							top: "15%",
							left: "40",
							right: "30",
							bottom: "18%",
						},
						xAxis: {
							type: "category",
							data: res?.data?.data?.xData,
							axisLabel: {
								// inside: true,
								color: "#fff",
							},
						},
						yAxis: {
							type: "value",
							splitLine: {
								show: true,
								lineStyle: {
									color: "#999",
									type: "solid",
								},
							},
							axisLine: {
								lineStyle: {
									color: "#fff",
								},
							},
						},
						series: [
							{
								data: res?.data?.data?.yData[0].data, // 纵坐标值
								type: "bar",
								name: res?.data?.data?.yData[0].name,
								itemStyle: {
									color: "rgba(255, 255, 255, 0)",
									borderColor: "#ffc824",
									borderWidth: "2",
								},
								barWidth: 16,
							},
							{
								data: res?.data?.data?.yData[1].data, // 纵坐标值
								type: "bar",
								name: res?.data?.data?.yData[1].name,
								barWidth: 16,
								itemStyle: {
									color: "#ffc824",
								},
							},
						],
					});
				}
			});

			setTimeout(() => {
				this.$nextTick(() => {
					this.MyChart.resize();
				});
			}, 0);
		},
		sizechange() {
			// 修改 echart 大小
			this.MyChart.resize();
		},
	},
	mounted() {
		// 页面初始化后执行
		this.CreateChart();
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style>
.vue-chart-setting {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 24px;
	background: url("../../assets/settings.svg");
	background-size: 16px 16px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}
.vue-chart-close {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 2px;
	background: url("../../assets/x.svg");
	background-size: 18px 18px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}
</style>

<!--本月工位预期收入-->
<template>
	<div class="strip_wrap">
		<div class="son-title">本月工位预期收入</div>
		<div
			class="strip_item"
			v-for="(item, i) in customerWorkedTimeTopTen"
			:key="i"
		>
			<div class="strip_text">
				<div>No.{{ i + 1 }}</div>
				<div>{{ item.name }}</div>
			</div>
			<div class="strip">
				<div style="width: 100%">
					<span
						:style="{ width: (item.value / this.maxTime) * 100 + '%' }"
					></span>
				</div>
				<div style="min-width: 80px; text-align: left">{{ item.value }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { apiCurrentMonthTopTen } from "@/api/ChartsDataRequest";
export default {
	name: "robot_deployed_data",
	props: [],
	emits: ["closeChart"],
	data() {
		return {
			customerWorkedTimeTopTen: [],
			maxTime: "",
			loading: true,
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},
		// 图表创建
		createChart() {
			this.loading = false;
			apiCurrentMonthTopTen().then((res) => {
				if (res.data.code == 200) {
					this.customerWorkedTimeTopTen = res?.data?.data || [];
					this.maxTime =
						this.customerWorkedTimeTopTen.length > 0
							? Math.floor(this.customerWorkedTimeTopTen[0].value)
							: "";
					// console.log(this.maxTime, this.maxTime);
				} else {
					console.log("Customer Data request fail");
				}
				this.loading = false;
			});
		},
	},
	mounted() {
		this.createChart();
	},
};
</script>

<style lang="scss" scoped>
.strip_wrap {
	padding: 0 20px;
	color: #fff;
	--bg-start: rgb(247, 193, 45); //设置开始的渐变颜色
	--bg-end: rgb(249, 183, 7); //设置结束的渐变颜色
}
.son-title {
	padding: 8px;
	font-size: 18px;
}
.strip_wrap .strip_item .strip_text {
	display: flex;
	font-size: 14px;
	line-height: 30px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.strip_wrap .strip_item .strip_text :nth-child(1) {
	color: #f9b707;
	padding-right: 10px;
}
.strip_wrap .strip_item .strip {
	// flex: 1;
	// height: 10px;
	display: flex;
	align-items: center;
}
.strip_wrap .strip_item .strip :nth-child(1) {
	background-color: #2b4e4a;
	//flex: 1;
	height: 10px;
	position: relative;
}
.strip_wrap .strip_item .strip :nth-child(1) span {
	display: block;
	height: 10px;
	width: 90%;
	position: absolute;
	left: 0;
	/* Firefox 3.6+ */
	background: -moz-linear-gradient(
		to right,
		var(--bg-start) 30%,
		var(--bg-end) 100%
	);
	/* Safari 4-5, Chrome 1-9 */
	background: -webkit-gradient(
		to right,
		var(--bg-start) 30%,
		var(--bg-end) 100%
	);
	/* Safari 5.1+, Chrome 10+ */
	background: -webkit-linear-gradient(
		to right,
		var(--bg-start) 30%,
		var(--bg-end) 100%
	);
	/* Opera 11.10+ */
	background: -o-linear-gradient(
		to right,
		var(--bg-start) 30%,
		var(--bg-end) 100%
	);
	background: linear-gradient(
		to right,
		var(--bg-start) 30%,
		var(--bg-end) 100%
	);
	/*IE<9>*/
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=var(--bg-start),endColorstr=var(--bg-end));

	/*IE8+*/
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=var(--bg-start), endColorstr=var(--bg-end))";
}
.strip_wrap .strip_item .strip :nth-child(2) {
	min-width: 30px;
	padding: 0 10px;
	text-align: center;
}
</style>

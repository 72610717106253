<!-- = 本月所有机器人实际工时*默认工时费


分子：本月总预期收入
分母：机器人数量 * 本月已过工作日（含今日） * 日保底工时 * 默认工时费

环比上月 = 本月总预期收入/上月同期预期收入-1

上周预期收入 = 上月所有机器人上周实际工时之和 * 默认工时费
收入金额四舍五入不保留小数。
环比百分比四舍五入不保留小数如：21%-->
<template>
	<div class="chart-drobot-title">本月预期总收入</div>
	<div class="total_moon_expected_income">
		<div id="total_moon_expected_income"></div>
		<div class="right-text">
			<div class="text-item">
				<div class="text-div">上周同期预期收入 <span>{{ last_week_income }}</span></div>
				<div class="text-div">本周总预期收入 <span>{{ week_income }}</span></div>
			</div>
		</div>

	</div>
	<div class="total_moon_expected_income_bot">
		<div class="text-item">
			<div :class="parseInt(month_rate)<0?'text-color':''">{{ month_rate }}</div>
			<div class="son-title">环比上月</div>
		</div>
		<div class="text-item">
			<!-- :class="text-color" -->
			<div :class="parseInt(week_rate)<0?'text-color':''" >{{ week_rate }}</div>
			<div class="son-title">环比上周</div>
		</div>
	</div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import { total_moon_expected_income } from "@/api/ChartsDataRequest";
import Progress from "./todayWorkHour.js";
let progressDom = null;
let progressData = {};
export default {
	name: "customer_status_profile",
	props: ["xref", "type", "chartData"],
	emits: ["closeChart"],
	data() {
		return {
			option: {},
			loading: false,
			last_week_income: 0,
			week_income: 0,
			month_rate: '',
			week_rate: '',
			isShow:''
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},

		// 图表创建
		createChart() {
			progressDom = new Progress(progressData);
			progressDom.init();
		},
		sizechange() {
			progressDom.remove();
			setTimeout(() => {
				progressDom = new Progress(progressData);
				progressDom.init();
			}, 200);
		},
	},
	async mounted() {
		const res = await total_moon_expected_income()
		if (res.data.code == 200) {
			this.last_week_income =Math.round(res.data.data.last_week_income)
			this.week_income = res.data.data.week_income //本周预期收入
			this.month_rate = res.data.data.month_rate
			this.week_rate = res.data.data.week_rate
			progressData = {
				dom: document.getElementById("total_moon_expected_income"),
				innerRadius: 60, //内圆半径
				outerRadius: 70, //外圆半径
				// fontSize: 20,
				fontColor: "#fff",
				suffix: " ",
				// extra: '<div style="color:#fff;font-size:14px">本月预期总收入</div>',
				duration: 1000,
				isNeedLineHeight:false,
				// value: Math.floor((res.data.data.work_income/res.data.data.standard_income)*100)/100,
				value:res.data.data.work_income/res.data.data.standard_income,
				customRingValue:res.data.data.work_income+'<div style="color:#fff;font-size:14px">本月预期总收入</div>',
				textDomClass:'chartCustomRingValue_center',
				color: [
						["rgb(249, 189, 7)", 0],
						["rgb(253, 189, 90 )", 1],
				],
			};
		}
		setTimeout(() => {
			this.createChart();
		}, 800);
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style lang="scss" scoped>
.chart-drobot-title {
	color: #fff;
	font-size: 18px;
	padding-top: 8px;
}

.son-title {
	font-size: 14px;
}

.total_moon_expected_income {
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100% - 120px);
	width: 100%;
	position: relative;

	&::after {
		content: "";
		display: block;
		width: 80%;
		height: 2px;
		background-color: #69827e;
		position: absolute;
		bottom: -10px;
	}

	.right-text {
		color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 0;
		.text-item {
			text-align: center;
			padding: 0;
			.text-div {
				font-size: 16px;
				color: white;
				span {
					font-size: 18px;
					margin-left: 5px;
					color: #f9b707;
				}
			}

		}
		
	}
}

#total_moon_expected_income {
	width: 40%;
	height: 100%;
}

.total_moon_expected_income_bot {
	display: flex;
	padding: 20px 0;

	.text-item {
		flex: 1;
		text-align: center;
		font-size: 12px;
		color: #fff;

		div:first-child {
			font-size: 24px;
			color: #f9b707;
		}
		.text-color{
			color: #209e1e !important;
		}
	}
}
</style>


<template>
	<div v-loading="loading" @click="sizechange" ref="MyChartDom" class="my_tasks">
		<div class="arrow">
			<el-icon color="#fff" :size="20" @click="move('left')">
				<ArrowLeftBold />
			</el-icon>
		</div>
		<div class="arrow arrow-right" >
			<el-icon color="#fff" :size="20" @click="move('right')">
				<ArrowRightBold />
			</el-icon>
		</div>
		<div class="my_tasks_title">我的待办</div>
		<div class="my_tasks_item_wrap" :style="{left: num+'px'}">
			<div class="item-row" v-for="(item,i) in list" :key="i">
				<div class="item">
				
					<div class="my_tasks_text" @click="goUrl(item.pathKey)">
						<div>{{item.num}}</div>
						<div>{{item.name}}</div>
					</div>
				</div>
			</div>
		</div>

	</div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import {my_tasks,labelList}  from "@/api/ChartsDataRequest";

import { ElMessage } from 'element-plus'
import { ArrowRightBold, ArrowLeftBold } from "@element-plus/icons-vue";
export default {
	name: "ranked_big",
	props: ["xref", "type", "chartData"],
	emits: ["closeChart"],
	data() {
		return {
			option: {},
			loading: true,
			num:0,
			current:1,
			rowNum:3,
			list:[
				// {name:'我的待办',num:0},
		
			],
			isShow:"",
			w:'100%'
		};
	},
	components: {
		ArrowRightBold, ArrowLeftBold
	},
	methods: {
		goUrl(key){
			// const routeMap={
			// 	customer_base_info:'/customerManage/customerInfo',//客户基础信息
			// 	customer_demand:'/customerManage/planRequirements',//客户工位需求
			// 	fixture_order:'/customerManage/fixtureOrder',//夹治具订单
			// 	work_station_scheme:'/customerManage/stationScheme',//工位方案
			// 	transfer_warehouse_order_fixture:'/storehouseManage/jigStorage',//夹治具仓库入库
			// 	transfer_warehouse_order_product:'/storehouseManage/specimenProducbeStorage',//产品样品仓库入库
			// 	transfer_warehouse_order_other:'/storehouseManage/otherDTS',//其他入库
			// 	work_station_cost:'/customerManage/customerInfo',//工位计费
			// 	work_station_group_cost:'/customerManage/customerInfo',//工位组计费
			// 	transfer_order:'/storehouseManage/transferOrder',//调拨单
			// 	daily_settlement:'/financialManage/dailyFinancialDevOps',//日结核签
			// 	month_settlement:'/financialManage/monthlySettlementDevOps',//月结核签
			// 	transfer_delivery_order:'/storehouseManage/transferOut',//调拨单出库
			// 	transfer_receipt_document:'/storehouseManage/transferIn',//调拨单入库
			// 	universal_work_station_scheme:'/universalSolution/universalWorkstationScheme',//通用工位方案
			// 	other_delivery_orders:'/storehouseManage/otherOutbound',//其他出库
			// 	customer_clue_cancel :'/CRM/cluePool',//线索作废
			// 	transfer_outbound_order:'/storehouseManage/salesOutbound'//销售出库(字典名字是：仓库出库)
			// }
			// console.log(key,routeMap[key]);
			if(key){//routeMap[key]
				// window.$wujie.props.method.propsMethod(routeMap[key])
				window.$wujie.props.method.propsMethod(`/personalAffairs/iApprove?key=${key}`)
			}else{
				this.$message({
					message: "前端路由映射暂未更新",
					type: "warning",
					duration: 1000,
				});
			}
			// window.$wujie.props.method.propsMethod('iApprove')
			
		},
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},
		move(type){
			const visual_w=this.$refs['MyChartDom'].offsetWidth
			if(type==='left'){
				if(parseInt(this.num)===0){
					ElMessage.success('已是第一个')
					return false
				}
				
				this.num=(Number(this.num)+Number(visual_w))
			}else{
				
				if(Math.abs(this.num)>=parseInt(this.w)-parseInt(visual_w)){
					ElMessage.success('已是最后一个')
					console.log('已是最后一个',this.num);
					return false
				}
				
				this.num=(Number(this.num)-Number(visual_w))
				// console.log(this.num);
			}
			// console.log(type,this.current,this.num);
		},
		sortBy(property,asc){
				return function (value1, value2) {
						let a = value1[property]
						let b = value2[property]
						// 默认升序
						if(asc==undefined){
								return a-b
						}else{
								return asc? a-b:b-a
						}
				}
		},
	},


	async mounted() {
		const arr=[]
		const res= await labelList({
			dict_identification:'flow_form_list',
			page:1,
			page_rows:1000
		})
		if(res.data.data.list.length>0){
			const keys=	res.data.data.list.reduce((prev, cur) => prev.concat(cur.label_value), [])
      .toString()
				const tasksRes= await my_tasks({
					form_keys:keys
				})
				// console.log(tasksRes,'tasksRes');
				res.data.data.list.forEach(item=>{
				// this.list[0].num+=parseInt(tasksRes.data.data[item.label_value])
					arr.push({name:item.dict_label,num:tasksRes.data.data[item.label_value],pathKey:item.label_value})
				})
				// console.log(this.sortBy);
				this.list=arr.sort(this.sortBy('num',false))
			// console.log(this.list.length,11);
			this.w=`${this.list.length*200}px`
		}
// console.log(this.list);



		this.loading = false
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style  lang="scss">
.vue-resizable-handle{
	z-index: 11;
}
.vue-chart-setting {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 24px;
	background: url("../../assets/settings.svg");
	background-size: 16px 16px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}

.vue-chart-close {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 2px;
	background: url("../../assets/x.svg");
	background-size: 18px 18px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}

.el-row {
	width: 100%;
}

.my_tasks {
	overflow: hidden;
	width: 100%;
	height: 100%;

	// display: flex;
	.arrow {
		position: absolute;
		left: 0;
		height: 80%;
		bottom: 0;
		display: flex;
		align-items: center;
		z-index: 10;
		cursor: pointer;
		background-color: #143230;
	}

	.arrow-right {
		left: auto;
		right: 0;
	}

	.my_tasks_title {
		font-size: 20px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 20%;
		background-color: rgba(255, 255, 255, 0.08);
		color: #fff;
		
	}

	.my_tasks_item_wrap {
		width: v-bind(w);
		height: 80%;
		text-align: left;
		// display: flex;
		align-items: center;
		transition:all 0.1s ease-in-out;
		position: relative;
		.item-row {
			// flex-basis: 33.33%;
			// flex-shrink: 0;
			// width: 33.33%;
			width: 150px;
			height: 100%;
			display: inline-block;
			text-align: left;
			.item {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				.svg {
					width: 25%;
					// height: 0;
					// padding-bottom: 100%;
					background-color: #262421;
					border-radius: 10px;

					svg {
						// width: 100%;
						// height: 100%;
					}
				}

			}
		}


	}



	.el-col-6 {
		height: 100% !important;
	}

	.my_tasks_text {
		flex-direction: column;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 14px;
		width: 100%;
		text-align: center;
		div{
			cursor: pointer;
		}
		>div:nth-child(1) {
			color: rgba(249, 183, 7, 1);
			font-size: 24px;
		}

		>div:nth-child(2) {
			padding: 8px 0;
			width: 80%;
			margin: 0 auto;
		}


	}

}
</style>

<!-- 今日正常待料 -->
<template>
	<div
		@click="sizechange"
		v-loading="loading"
		:style="{ width: '100%', height: '100%', overflow: 'hidden' }"
	>
		<div class="circle-title">今日正常待料</div>
		<div class="today_work_hours_pie">
			<div id="normal-material"></div>
			<div class="text-item">
				<div>环比昨日</div>
				<div class="today-num" :style="{color:(dayRate<0?'#3FA864':'')}">{{ dayRate=='NaN'||dayRate=='Infinity'?0+'%':dayRate+'%' }}</div>
			</div>
			<div class="text-item">
				<div>昨日正常待料</div>
				<div class="today-num">{{ waitTime+'h' }}</div>
			</div>
		</div>
	</div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import {apiGetCurrentdayNormalMaterial} from "@/api/ChartsDataRequest";
import Progress from "./todayWorkHour.js";
let progressDom = null;
let progressData = {};
export default {
	name: "customer_status_profile",
	props: ["xref", "type", "chartData"],
	emits: ["closeChart"],
	data() {
		return {
			loading: false,
      waitTime:0,
      dayRate:'',
			isShow:''
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},

		// 图表创建
		createChart() {
			progressDom = new Progress(progressData);
			progressDom.init();
		},
		sizechange() {
			progressDom.remove();
			setTimeout(() => {
				progressDom = new Progress(progressData);
				progressDom.init();
			}, 200);
		},
	},
	async mounted() {
		const res = await apiGetCurrentdayNormalMaterial({type:'waiting_time'})
		if(res.data.code==200){
        this.waitTime = res.data.data.last_day_wait_time // 昨日正常待料
				let digit = (res.data.data.wait_time - res.data.data.last_day_wait_time)/ res.data.data.last_day_wait_time*100

				if(Number.isInteger(digit)){//判断否为整数
					this.dayRate = digit
				}else{
					this.dayRate = digit.toFixed(1)
				}
        progressData = {
        dom: document.getElementById("normal-material"),
        innerRadius: 60, //内圆半径
        outerRadius: 70, //外圆半径
        fontSize: 22,
        fontColor: "#fff",
        suffix: " ",
        // extra: '<div style="color:#fff;font-size:14px">今日正常待料</div>',
        duration: 1000,
        value: res.data.data.wait_time==0?'0':Math.round(res.data.data.wait_time /res.data.data.standard_hour),
				customRingValue:res.data.data.wait_time+'h'+'<div style="color:#fff;font-size:16px">今日正常待料</div>',
				textDomClass:'chartCustomRingValue_center',
        color: [
          ["rgb(246, 184, 14)", 0],
          ["rgb(246, 184, 14)", 0.5],
          ["rgb(246, 183, 14)", 1],
        ],
		};
      }
		setTimeout(() => {
			this.createChart();
		}, 800);
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style>
.vue-chart-setting {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 24px;
	background: url("../../assets/settings.svg");
	background-size: 16px 16px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}
.vue-chart-close {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 2px;
	background: url("../../assets/x.svg");
	background-size: 18px 18px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}
.circle-title {
	color: #fff;
	font-size: 18px;
	margin-top: 8px;
}
#normal-material {
	height: 70%;
	width: 100%;
	/* background: rgb(248, 3, 3); */
}
.today_work_hours_pie {
	height: 100%;
	width: 100%;
	flex-direction: column;
}
.today_work_hours_pie .text-item {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 14px;
	color: #fff;
}
.today_work_hours_pie .text-item > div:first-child {
	color: #fff;
	font-size: 14px;
}

.today_work_hours_pie .text-item > div:last-child {
	/* font-size: 18px; */
	color: #f9b707;
	padding-left: 20px;
}
.today-num {
	font-size: 18px;
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import VueGridLayout from 'vue-grid-layout'
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/theme-chalk/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import V3waterfall from 'v3-waterfall'
import 'v3-waterfall/dist/style.css'
import store from './store'
// import loadMore from './utils/loadmore'



if (window.__POWERED_BY_WUJIE__) {
  let instance;
  window.__WUJIE_MOUNT = () => {

    instance = createApp(App);
    instance.use(store);
    instance.use(VueGridLayout)
    instance.use(ElementPlus, {
      locale: zhCn,
    })

    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
      instance.component(key, component)
    }

    instance.use(V3waterfall)
    instance.mount("#app");
  };
  window.__WUJIE_UNMOUNT = () => {
    instance.unmount();
  };
} else {
  let instance;
  instance = createApp(App)
  instance.use(store);
  instance.use(VueGridLayout)
  instance.use(ElementPlus, {
    locale: zhCn,
  })
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    instance.component(key, component)
  }
  // instance.use(loadMore)
  instance.use(V3waterfall)

  instance.mount('#app')
}


import request from '@/utils/request'

// 保存图表分组信息
export function apiSavechartGroup(data) {
  return request({
    url: '/chartsapi/api/admin/bulletin/save-group',
    method: 'post',
    data: data
  })
}

// 获取图表分组
export function apiGetchartsList(data) {
  return request({
    url: '/chartsapi/api/admin/bulletin/chart-list',
    method: 'get',
    params: data
  })
}


//   删除图表分组
export function apiDeleteChartGroup(gid) {
  return request({
    url: `/chartsapi/api/admin/bulletin/delete-group?id=${gid}`,
    method: 'delete'
  })
}


// 获取图表列表
export function apiGetSelectableCharts(data) {
  const {gid='', type=''} = data;
  return request({
    url: `/chartsapi/api/admin/bulletin/get-group-chart?group_id=${gid}&type=${type}`,
    method: 'get'
  })
}

// 保存选中的图表
export function apiSaveSelectedCharts(data) {
  return request({
    url: `/chartsapi/api/admin/bulletin/save-group-board`,
    method: 'post',
    data: data
  })
}

// 获取投资人可选择的图表
export function investorChart(data) {
  return request({
    url: `/chartsapi/api/admin/bulletin/get-investor-chart?group_id=${data}`,
    method: 'get'
  })
}

// 投资人看板
export function investorhart(data) {
  return request({
    url: `/chartsapi/api/admin/bulletin/investor-chart`,
    method: 'get',
    params: data
  })
}


// 保存图表框的大小配置
export function apiSavechartsBox(data) {
  return request({
    url: `/chartsapi/api/admin/bulletin/save-flexible`,
    method: 'post',
    data: data
  })
}

// 获取图表数据类型
export function apiGetChartsType() {
  return request({
    url: `/chartsapi/api/admin/data-dict/label-list?dict_identification=charts_type&dict_label=&status=1&page=1&page_rows=100`,
    method: 'get',
  })
}

// 从图表组中删除某显示图表
export function apiDeleteCartAtgroup(delData) {
  const{group_id,board_id} = delData;
  return request({
    url: `/chartsapi/api/admin/bulletin/del-group-chart?group_id=${group_id}&board_id=${board_id}`,
    method: 'delete',
  })
}

// 获取小看板权限数据
export function apichartDoard(data) {
  return request({
    url: `/chartsapi/api/admin/board/chart-board`,
    method: 'get',
    params: data
  })
}
// 删除小看板
export function delDoard(data) {
  return request({
    url: `/chartsapi/api/admin/board/del-group-chart`,
    method: 'delete',
    data: data
  })
}



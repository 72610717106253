<template>
	<div
		@click="sizechange"
		ref="MyChartDom"
		:style="{ width: '100%', height: '100%', overflow: 'hidden' }"
	></div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import * as echarts from "echarts";
import { chartsData } from "../../testData/mockData.js";

const chartTitle = {
	left: "left",
	textStyle: {
		fontSize: 14,
	},
};
export default {
	name: "custormer_default",
	props: ["xref", "type", "chartData"],
	emits: ["closeChart", "settingChart"],
	data() {
		return {
			option: {
				// 图表标准件
				title: {
					text: "Defaut Name", // 标题
					...chartTitle,
				},
				tooltip: {
					trigger: "axis",
					axisPointer: { type: "cross" },
				},
				xAxis: {
					type: "category",
				},
				yAxis: {
					type: "value",
				},
				series: [],
			},
			chartBoxSetting: {
				type: "bar",
				name: "", // chart 标题
				xdata: [],
				series: [],
			},
			isShow:''
		};
	},
	methods: {
		handleCloseChart() {
			this.$emit("closeChart");
		},
		handleSettingChart() {
			this.$emit("settingChart", this.chartData.i);
		},
		CreateChart() {
			this.ChartDom = this.$refs.MyChartDom; // 获取到你防止echart的Dom
			this.MyChart = echarts.init(this.ChartDom); // 实例化你的 echart 图
			const getOneData = chartsData.filter((item) => {
				return item.chartId === this.chartData.i;
			});
			const curentData = Array.isArray(getOneData) ? getOneData[0] : "";
			if (curentData) {
				if (curentData.type === "pie") {
					// 饼状图单独处理
					this.MyChart.setOption({
						...this.option,
						title: {
							text: curentData.title,
							...chartTitle,
						},
						tooltip: {
							trigger: "item",
							formatter: "{a} <br/>{b} : {c} ({d}%)",
						},
						xAxis: { show: false },
						yAxis: { show: false },
						series: [
							{
								name: "Access From",
								type: curentData.type,
								radius: "50%",
								label: {
									show: true,
									formatter(param) {
										return param.name + " (" + param.value + ")";
									},
								},
								data: curentData.data,
							},
						],
					});
				} else {
					this.MyChart.setOption({
						...this.option,
						title: {
							text: curentData.title,
							...chartTitle,
						},
						series: curentData.data.series,
						xAxis: curentData.data.xAxis,
					});
				}
			}

			setTimeout(() => {
				this.$nextTick(() => {
					this.MyChart.resize();
				});
			}, 0);
		},
		sizechange() {
			// 修改 echart 大小
			this.MyChart.resize();
		},
	},
	mounted() {
		// 页面初始化后执行
		this.CreateChart();
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style>
.vue-chart-setting {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 24px;
	background: url("../../assets/settings.svg");
	background-size: 16px 16px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}
.vue-chart-close {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 2px;
	background: url("../../assets/x.svg");
	background-size: 18px 18px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}
</style>

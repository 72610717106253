import { createStore } from 'vuex'

import createPersistedState from 'vuex-persistedstate'
export default createStore({
  state: {
    currentPanel:localStorage.getItem('currentPanel')?localStorage.getItem('currentPanel'):null  ,//首页
    currentPanelInvestorBoard:localStorage.getItem('currentPanelInvestorBoard')?localStorage.getItem('currentPanelInvestorBoard'):null,//投资人看板
    invesValue:localStorage.getItem('invesValue')?localStorage.getItem('invesValue'):''
  },
  mutations: {
    setCurrentPanel(state, val) {
        state.currentPanel = val;
        localStorage.setItem('currentPanel',val)
    },
    setCurrentPanelInvestorBoard(state, val) {
      localStorage.setItem('currentPanelInvestorBoard',val)

     state.currentPanelInvestorBoard = val;
    },
    setInvesValue(state, val) {
      localStorage.setItem('invesValue',val)
      state.invesValue = val;
     },
    
    
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    // veux持久化配置
    createPersistedState()
  ]
})


<!-- 今日稼动率/环比昨日 -->
<template>
	<div
		@click="sizechange"
		ref="MyChartDom"
		v-loading="loading"
		:style="{ width: '100%', height: '80%', overflow: 'hidden' }"
	></div>
	<div class="text-item">
		<div>昨日稼动率</div>
		<div>{{ last_total_efficient }}</div>
	</div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import * as echarts from "echarts";
import { today_utilization_rate } from "@/api/ChartsDataRequest";

export default {
	name: "customer_status_profile",
	props: ["xref", "type", "chartData"],
	emits: ["closeChart"],
	data() {
		return {
			option: {},
			loading: true,
			last_total_efficient:'10',
			isShow:''
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},
		getEqualNewlineString(params, length) {
			if (!params) return "";
			if (params.length > length) {
				//10是截取的字符串长度，根据你的情况调整数值
				params = params.slice(0, length) + "...";
			}
			return params;
		},

		// 图表创建
		async createChart() {
			this.ChartDom = this.$refs.MyChartDom;
			this.MyChart = echarts.init(this.ChartDom, null);
      const res = await today_utilization_rate()
      if(res.data.code === 200){
        this.last_total_efficient = res.data.data.last_total_efficient
				let efficient = res.data.data.daily_efficient==0?res.data.data.daily_efficient:res.data.data.daily_efficient.replace("%",'')
			
     const option = {
			title: [
				{
					text: "今日稼动率",
					left: "center",
					top: "2%",
					textStyle: {
						color: "#fff",
						fontWeight: "normal",
						fontSize: "18px",
					},
				},
    {
      text: "今日稼动率",
      x: "center",
      top: "60%",
      textStyle: {
        color: "#FFFFFF",
        fontSize: 14,
        fontWeight: "100",
      },
    },
    {
      text: res.data.data.daily_efficient,//名称
      x: "center",
      y: "center",
      textStyle: {
        fontSize: "20",
        color: "#FFFFFF",
        fontFamily: "DINAlternate-Bold, DINAlternate",
        foontWeight: "600",
      },
    },
  ],
  polar: {
    radius: ["53%", "63%"],
    center: ["50%", "58%"],
  },
  angleAxis: {
    max: 100,
    show: false,
  },
  radiusAxis: {
    type: "category",
    show: true,
    axisLabel: {
      show: false,
    },
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
  },
  series: [
    {
      name: "",
      type: "bar",
      roundCap: true,
      barWidth: 30,
      showBackground: true,
      backgroundStyle: {
        color: "rgba(41, 75, 72, .3)",
      },
      data: [efficient],
      coordinateSystem: "polar",
      itemStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
            {
              offset: 0,
              color: "rgb(246, 222, 98)",
            },
            {
              offset: 1,
              color: "rgb(246, 180, 70)",
            },
          ]),
        },
      },
    },
        ]
      }
      this.MyChart.setOption(option);
			this.loading = false;
    }
     
			setTimeout(() => {
				this.$nextTick(() => {
					this.MyChart.resize();
				});
			}, 0);
		},
		sizechange() {
			this.MyChart.resize();
		},
	},
	mounted() {
		this.createChart();
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style lang="scss" scoped>
.vue-chart-setting {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 24px;
	background: url("../../assets/settings.svg");
	background-size: 16px 16px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}

.vue-chart-close {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 2px;
	background: url("../../assets/x.svg");
	background-size: 18px 18px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}
.text-item {
	display: flex; 
	justify-content: center;
}
.text-item div:first-child {
	padding-right:10px;
	font-size: 14px;
	color: #fff;
}
.text-item div:last-child{
	font-size: 18px;
	color: #f9b707;
}
</style>

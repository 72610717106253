<!-- 12个月工位数量变化 -->
<template>
	<div
		@click="sizechange"
		ref="MyChartDom"
		v-loading="loading"
		:style="{ width: '100%', height: '100%', overflow: 'hidden' }"
	></div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import * as echarts from "echarts";
import { month_station_number_change } from "@/api/ChartsDataRequest";

export default {
	name: "customer_status_profile",
	props: ["xref", "type", "chartData"],
	emits: ["closeChart"],
	data() {
		return {
			option: {},
			loading: true,
			isShow:''
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},
		getEqualNewlineString(params, length) {
			if (!params) return "";
			if (params.length > length) {
				//10是截取的字符串长度，根据你的情况调整数值
				params = params.slice(0, length) + "...";
			}
			return params;
		},

		// 图表创建
		async createChart() {
			this.ChartDom = this.$refs.MyChartDom;
			this.MyChart = echarts.init(this.ChartDom, null);
      const res = await month_station_number_change()
      if(res.data.code === 200){
        const nres = {
						xData: res?.data?.data.xData,
						yData: res?.data?.data.yData,
					};
          const tData = nres.xData
          const xData1 = nres.yData.find(
						(item) => item.name === "工位数量"
					).data;
          const xData2 = nres.yData.find(
						(item) => item.name === "未就绪工位"
					).data;
					const xData3 = nres.yData.find(
						(item) => item.name === "就绪工位"
					).data;
     const option = {
        title: {
					text: "12个月工位数量变化",
					left: "center",
					top: "2%",
					textStyle: {
						color: "#fff",
						fontWeight: "normal",
						fontSize: "18px",
					},
				},
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
        },
        legend: {
          bottom: "16",
						textStyle: {
						color: "#fff",
						fontSize: "14px",
						// padding: [0, 0, 0, 0],
					},
        },
        grid: {
          top: 60,
          left:40,
          right: 50,
          bottom: 60,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            axisLabel: {
							color: "#fff", // 更改坐标轴文字颜色
							fontSize: 14, // 更改坐标轴文字大小
						},
            data:tData,
          }
        ],
        yAxis: [
          {
            type: 'value',
            position: "left",
            splitLine: {
							//Y轴线的颜色
							show: true,
							interval: "auto",
							lineStyle: {
								color: ["#546f6d"],
                width:2
							},
						},
            //y轴左侧文字颜色
						axisLine: {
							onZero: true,
							lineStyle: {
                color: "#fff",
              },
						},
						// y轴左侧文字大小
						axisLabel: {
							margin: 10,
							textStyle: {
								fontSize: 12,
							},
						},
          }
        ],
        series: [
          {
            name: '工位数量',
            type: 'bar',
            color:'#A4C3DC',
            barWidth:20,
            emphasis: {
              focus: 'series'
            },
            data: xData1
          },
          {
            name: '未就绪工位',
            type: 'bar',
            color:'#F6F7C9',
            barWidth: 10,
            stack: 'Search Engine',
            emphasis: {
              focus: 'series'
            },
            data: xData2
          },
          {
            name: '就绪工位',
            type: 'bar',
            color:'#78C75C',
            stack: 'Search Engine',
            emphasis: {
              focus: 'series'
            },
            data: xData3
          }
        ]
      }
      this.MyChart.setOption(option);
			this.loading = false;
    }
     
			setTimeout(() => {
				this.$nextTick(() => {
					this.MyChart.resize();
				});
			}, 0);
		},
		sizechange() {
			this.MyChart.resize();
		},
	},
	mounted() {
		this.createChart();
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style>
.vue-chart-setting {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 24px;
	background: url("../../assets/settings.svg");
	background-size: 16px 16px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}

.vue-chart-close {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 2px;
	background: url("../../assets/x.svg");
	background-size: 18px 18px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}
</style>


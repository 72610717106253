<!--本月客户数量对比上月-->
<template>
	<div class="chart-drobot-title">本月客户数量对比上月</div>
	<div class="robot-data">
		<svg viewBox="0 0 106 106" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M95.9437 98.7962C94.5476 89.2118 89.8915 80.3925 82.6202 73.68C78.5179 69.893 73.7802 66.9631 68.6579 64.9869C71.5483 63.4795 74.224 61.5243 76.595 59.1533C82.7002 53.048 86.0625 44.9308 86.0625 36.2967C86.0625 27.6626 82.7002 19.5454 76.595 13.4401C70.4898 7.33498 62.3725 3.97266 53.7385 3.97266C45.1045 3.97266 36.9871 7.33498 30.8819 13.4402C24.7768 19.5454 21.4144 27.6627 21.4144 36.2968C21.4144 44.9309 24.7768 53.0481 30.8819 59.1534C33.0046 61.276 35.3709 63.0662 37.9172 64.4996C32.2856 66.4671 27.0823 69.5694 22.6281 73.6819C15.3563 80.3956 10.7007 89.2162 9.30576 98.8018C9.04363 100.603 10.407 102.241 12.2596 102.357L12.4642 102.37C14.1295 102.474 15.5861 101.3 15.8235 99.6805C17.0151 91.553 20.9685 84.0734 27.1385 78.3769C33.6598 72.3558 42.0716 68.8847 50.979 68.503C51.8918 68.5797 52.8119 68.6208 53.7385 68.6208C54.3198 68.6208 54.8985 68.6043 55.4747 68.5741C63.9336 69.2039 71.8836 72.6273 78.1102 78.3753C84.2799 84.0709 88.2337 91.5493 89.4262 99.6758C89.6639 101.295 91.1206 102.469 92.7859 102.365L92.9904 102.352C94.8428 102.236 96.2062 100.598 95.9437 98.7962ZM27.9931 36.2967C27.9931 29.4199 30.6711 22.9547 35.5338 18.092C40.3964 13.2294 46.8616 10.5514 53.7385 10.5514C60.6153 10.5514 67.0805 13.2293 71.9432 18.092C76.8058 22.9547 79.4838 29.4199 79.4838 36.2967C79.4838 43.1735 76.8058 49.6387 71.9432 54.5014C67.1431 59.3013 60.7813 61.9708 54.0038 62.0388C53.5464 62.0248 53.088 62.0171 52.6286 62.0171C46.1684 61.7449 40.1339 59.1014 35.5339 54.5014C30.6712 49.6387 27.9931 43.1735 27.9931 36.2967Z"
				fill="#F9B707"
			/>
		</svg>

		<div class="right-text">
			<div class="text-item text-number">
				<div>{{ customerTotal }}</div>
				<div>客户总数</div>
			</div>
		</div>
	</div>
	<div class="robot-data-bot">
		<div class="text-item">
			<div :style="{color:(addRate.indexOf('-',0)==0?'#3FA864':'')}">{{ addRate.indexOf('-',0)!==0?'+'+addRate:addRate }}</div>
			<div class="son-title">环比上月</div>
		</div>
		<div class="text-item">
			<div>{{ monthAdd }}</div>
			<div class="son-title">本月新增</div>
		</div>
	</div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import { apiCustomerJionedCompare } from "@/api/ChartsDataRequest";
export default {
	name: "terminated_customer",
	props: [],
	emits: ["closeChart"],
	data() {
		return {
			customerTotal: 0,
			addRate: '',
			monthAdd: 0,
			loading: true,
			isShow:''
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},

		// 图表创建
		createChart() {
			this.loading = false;
			apiCustomerJionedCompare().then((res) => {
				if (res.data.code == 200) {
					const currentData = res?.data?.data || {};
					this.customerTotal = currentData.total || 0;
					this.addRate = currentData.add_rate||'0' ;
					this.monthAdd = currentData.month_add;
				} else {
					console.log("Robot Data request fail");
				}
				this.loading = false;
			});
		},
	},
	mounted() {
		this.createChart();
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style lang="scss" scoped>
.chart-drobot-title {
	color: #fff;
	font-size: 18px;
	padding-top: 8px;
}
.son-title {
	font-size: 14px;
}
.robot-data {
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100% - 120px);
	width: 100%;
	position: relative;

	&::after {
		content: "";
		display: block;
		width: 80%;
		height: 2px;
		background-color: #69827e;
		position: absolute;
		bottom: -10px;
	}

	svg {
		width: calc((100% / -130) * 100);
		height: calc((100% / 130) * 100);
	}

	.right-text {
		color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-left: 30px;
		.text-item {
			text-align: center;
			font-size: 14px;

			div:first-child {
				margin-top: 15px;
				font-size: 64px;
				color: #fff;
			}

			div:last-child {
				font-size: 20px;
				color: #f9b707;
				margin-top: 16px;
			}
		}
	}
}

.robot-data-bot {
	display: flex;
	padding: 20px 0;

	.text-item {
		flex: 1;
		text-align: center;
		font-size: 12px;
		color: #fff;

		div:first-child {
			font-size: 24px;

			color: #f9b707;
		}
	}
}
</style>

<!--人力结构-岗位-->
<template>
	<div class="tips" style="color: #fff;" v-if="showData.length===0" ></div>
	<div
		@click="sizechange"
		ref="MyChartDom"
		v-loading="loading"
		:style="{ width: '100%', height: '100%', overflow: 'hidden' }"
	></div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import * as echarts from "echarts";
import { apiHumanStructureJob } from "@/api/ChartsDataRequest";

export default {
	name: "customer_status_profile",
	props: ["xref", "type", "chartData"],
	emits: ["closeChart"],
	data() {
		return {
			option: {},
			loading: true,
			isShow:'',
			showData:[],
			// interval:0,//间隔
			// refreshTime:1 //刷新时间
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},
		getEqualNewlineString(params, length) {
			if (!params) return "";
			if (params.length > length) {
				//10是截取的字符串长度，根据你的情况调整数值
				params = params.slice(0, length) + "...";
			}
			return params;
		},

		// 图表创建
		createChart() {
			this.ChartDom = this.$refs.MyChartDom;
			this.MyChart = echarts.init(this.ChartDom, null);
			apiHumanStructureJob().then((res) => {
				if (res?.data?.code === 200) {
					this.showData = res?.data?.data;
					const total = this.showData.reduce(
						(prev, cur) => (prev = prev + parseInt(cur.value)),
						0
					);
					this.MyChart.setOption({
						title: {
							text: "人力结构-岗位",
							left: "center",
							top: "3%",
							textStyle: {
								color: "#fff",
								fontWeight: 400,
							},
						},
						tooltip: {
							trigger: "item",
						},
						color: [
							"rgba(66, 178, 102, 1)",
							"rgba(249, 183, 7, 1)",
							"rgba(245, 108, 108, 1)",
							"#ef6567",
							"#f9c956",
							"#75bedc",
						],
						legend: {
							orient: "vertical",
							right: "10%",
							top: "32%",
							textStyle: {
								//图例中文字的样式
								color: "#fff",
								fontSize: 12,
								padding: [0, 0, 0, 4], // 修改文字和图标距离
								rich: {
									name: {
										color: '#fff',
										fontSize: 12,
										align: 'left',

										// lineHeight: 40,
										width: 30
									},
									value: {
										color: '#F9B707',
										fontSize: 12,
										align: 'left',

										// lineHeight: 40,
										width: 30
									}

								},
							},
							itemStyle: {
								borderRadius: 1,
								borderColor: 'transparent',
								borderWidth: 1
							},
							formatter: (name) => {
								// console.log(res.find(item => item.name === name), name)
								let values =''
								if(this.showData){
									values = this.showData.find(item => item.name === name).value
								}
								
								let arr = []
								arr = [
									'{name|' + name +
									'}{value|' + values + '}'
								]
								return arr
							},
						},
						series: [
							{
								name: "人力结构",
								type: "pie",
								radius: ["40%", "70%"],
								center: ["30%", "56%"], //center: ['30%', '50%'],第一个值调整左右，第二个值调整上下，也可以设置具体数字像素值，center: [200, 300],
								avoidLabelOverlap: false,
								label: {
									show: true,
									position: "center",
									formatter: `${total}\n 总人数`,
									color: "#fff",
									fontSize: 16,
									lineHeight: 20,
								},
								labelLine: {
									show: false,
								},
								itemStyle: {
									borderRadius: 2,
									borderColor: '#fff',
									borderWidth: 1
								},
								data: this.showData,
							},
						],
					});
					this.loading = false;
				} else {
					console.log("Request failed!");
				}
			});

			setTimeout(() => {
				this.$nextTick(() => {
					this.MyChart.resize();
				});
			}, 0);
		},
		sizechange() {
			this.MyChart.resize();
		},
	},
	mounted() {
		this.createChart();
		this.isShow= sessionStorage.getItem("isShow")
		// this.refreshTime = sessionStorage.getItem('refreshTime')
		// this.interval=setInterval(()=>{
		// 	this.createChart();
		// },this.refreshTime*60000)
	},
	// destoryed(){
	// 	this.interval && clearInterval(this.interval)
	// }
};
</script>

<style>
.vue-chart-setting {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 24px;
	background: url("../../assets/settings.svg");
	background-size: 16px 16px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}

.vue-chart-close {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 2px;
	background: url("../../assets/x.svg");
	background-size: 18px 18px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}
</style>
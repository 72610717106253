<!--当前部署机器人数量-->
<template>
	<div
		@click="sizechange"
		v-loading="loading"
		:style="{ width: '100%', height: '100%', overflow: 'hidden' }"
	>
		<div class="robot-data">
			<img src="../../assets/icon_custumer_num.png" class="chart-img" />
			<div class="right-text">
				<div class="text-item">
					<div>{{ robotData.robot_num }}</div>
					<div>当前部署机器人数量</div>
				</div>
				<div class="text-item">
					<div>{{ robotData.man_hour }}h</div>
					<div>机器人总营业时间</div>
				</div>
			</div>
		</div>
	</div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import { apiGetRobotDeployData } from "@/api/ChartsDataRequest";
export default {
	name: "robot_deployed_data",
	props: [],
	emits: ["closeChart"],
	data() {
		return {
			robotData: {
				man_hour: "3738.05",
				robot_num: 39,
			},
			loading: true,
			isShow:''
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},

		// 图表创建
		createChart() {
			this.loading = false;
			apiGetRobotDeployData().then((res) => {
				/// console.log("res1", res);
				if (res.data.code == 200) {
					this.robotData = res?.data?.data;
				} else {
					console.log("Robot Data request fail");
				}
				this.loading = false;
			});
		},
	},
	mounted() {
		this.createChart();
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style>
.vue-chart-close {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 2px;
	background: url("../../assets/x.svg");
	background-size: 18px 18px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}
.robot-data {
	display: flex;
	align-items: center;
	justify-content: center;
	/* height: calc(100% - 50px ); */
	height: 100%;
	width: 100%;
}
.chart-img {
	display: block;
	width: calc((100% / 150) * 100);
	height: calc((100% / 150) * 100);
	object-fit: contain;
}
.right-text {
	color: #fff;
	display: flex;
	flex-direction: column;
	margin-right: 10%;
}
.text-item {
	text-align: center;
	line-height: 30px;
	font-size: 14px;
	color: #fff;
}
.text-item div:first-child {
	font-size: 32px;
	color: #f9b707;
}
.text-item:nth-child(1) {
	padding-bottom: 20px;
}
</style>

<!-- 当前部署机器人数对比 -->
<template>
	<div class="chart-drobot-title">当前部署机器人数对比</div>
	<div class="robot-data">
    <img src="../../assets/robot-data-icon.png">
		<div class="right-text">
			<div class="text-item ">
				<div>{{ customerTotal }}</div>
				<div>当前部署机器人数</div>
			</div>
		</div>
	</div>
	<div class="robot-data-bot">
		<div class="text-item">
			<div >{{ addRate }}</div>
			<div class="son-title">本月新增</div>
		</div>
		<div class="text-item">
			<div>{{ monthAdd }}</div>
			<div class="son-title">本周新增</div>
		</div>
	</div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import { current_arrange_robot_contrast } from "@/api/ChartsDataRequest";
export default {
	name: "terminated_customer",
	props: [],
	emits: ["closeChart"],
	data() {
		return {
			customerTotal: 0,
			addRate: '',
			monthAdd: 0,
			loading: true,
			isShow:''
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},

		// 图表创建
		async createChart() {
			this.loading = false;
      const res = await current_arrange_robot_contrast()
      if (res.data.code == 200) {
					const currentData = res?.data?.data || {};
					this.customerTotal = currentData.current_total || 0;
					this.addRate = currentData.month_add ;//本月新增
					this.monthAdd = currentData.week_add;//本周新增
				}
		},
	},
	mounted() {
		this.createChart();
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style lang="scss" scoped>
.chart-drobot-title {
	color: #fff;
	font-size: 18px;
	padding-top: 8px;
}
.son-title {
	font-size: 14px;
}
.robot-data {
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100% - 120px);
	width: 100%;
	position: relative;

	&::after {
		content: "";
		display: block;
		width: 80%;
		height: 2px;
		background-color: #69827e;
		position: absolute;
		bottom: -10px;
	}

	img {
		width: calc((100% / -190) * 100);
		height: calc((100% / 190) * 100);
	}

	.right-text {
		color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-left: 30px;
		.text-item {
			text-align: center;
			font-size: 14px;
      margin-top: 20px;

			div:first-child {
				margin-top: 15px;
				font-size: 64px;
				color: #fff;
        height: 50px;
			}

			div:last-child {
				font-size: 20px;
				color: #f9b707;
				margin-top: 16px;
			}
		}
	}
}

.robot-data-bot {
	display: flex;
	padding: 20px 0;

	.text-item {
		flex: 1;
		text-align: center;
		font-size: 24px;
		color: #fff;

		div:first-child {
			font-size: 24px;
			color: #f9b707;
		}
	}
}
</style>

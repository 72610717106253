<template>
  <div class="tab-container" id="tabContainer" >
    <div class="tab-item"  v-for="item in bulletin" :key="item">
    <div class="sub-item-title display_flex align_items_center justify_content_flex-justify">
          <div class="display_flex align_items_center">
            <div class="divider" />
            <span>{{ item.dict_label }}</span>
          </div>
            <el-checkbox
            v-model="state.checkAll[item.id]"
            :indeterminate="state.isIndeterminate[item.id]"
            @change="(val)=>handleCheckAllChange(val,item.id,item)"
            />
        </div>
        <div
          class="sub-item-list"
        >
          <el-checkbox-group
            v-model="checkedBoardId[item.id]"
            @change="(value)=>handleCheckedBoard(value,item.id,item)"
          >
            <el-checkbox
              v-for="litem in item.chart"
              :key="litem.id"
              :label="litem.id"
            >
              {{ litem.name }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
    </div>
  </div>
</template>
 

<script>
export default {
	name: "TestChart",
  props:{
    bulletin:Array
  },
  data() {
    return {
      checkedBoardId:{ },//id
      state:{
        checkAll: { }, // 全选 {'type_214':true,'type_215':true}
        isIndeterminate: { }, // 半选 {'type_214':true,'type_215':true}
      },
      check:{},
      bulletins:[]
    };
  },
  computed: {
   
  },
  mounted() {
    this.waterFall("#tabContainer", ".tab-item"); //实现瀑布流
    // 窗口变化自适应布局
    window.onresize = () => {
      return (() => {
        this.waterFall("#tabContainer", ".tab-item");
      })();
    };
    let checked = sessionStorage.getItem('checkedBoardId')
    if(JSON.parse(checked)){
      this.checkedBoardId = JSON.parse(checked)
    }
    let checkAll = sessionStorage.getItem('checkAll')
    if(JSON.parse(checkAll)){
      this.state.checkAll = JSON.parse(checkAll)
    }
    let isIndeter = sessionStorage.getItem('isIndeterminate')
    if(JSON.parse(isIndeter)){
      this.state.isIndeterminate = JSON.parse(isIndeter)
    }
  },
  methods: {
    //瀑布流方法：通过拿到dom循环，给每一个dom添加对应的定位位置排列出瀑布流布局。
    //通过判断列的高度，来把下一个盒子放在最短的地方补上
    
    waterFall(
      wrapIdName,
      contentIdName,
      columns = 4,
      columnGap = 10,//左右间隔空隙
      rowGap = 10//下间隔空隙
    ) {
      // 获得内容可用宽度（去除滚动条宽度）
      const wrapContentWidth =908
        // document.querySelector(wrapIdName).offsetWidth - 8;
      // 间隔空白区域
      const whiteArea = (columns - 1) * columnGap;
      
      // 得到每列宽度(也即每项内容宽度)
      const contentWidth = parseInt((wrapContentWidth - whiteArea) / columns);
      // 得到内容项集合
      const contentList =document.querySelectorAll(contentIdName)

      // 成行内容项高度集合
      const lineConentHeightList = [];

      for (let i = 0; i < contentList.length; i++) {
        // 动态设置内容项宽度
        contentList[i].style.width = contentWidth + "px";
        
        // 获取内容项高度
        const height = contentList[i].clientHeight;
        if (i < columns) {
          // 第一行按序布局
          contentList[i].style.top = 0;
          contentList[i].style.left = contentWidth * i + columnGap * i + "px";

          // 将行高push到数组
          lineConentHeightList.push(height);
        } else {
          // 其他行
          // 获取数组最小的高度 和 对应索引
          let minHeight = Math.min(...lineConentHeightList);
          let index = lineConentHeightList.findIndex(
            (listH) => listH === minHeight
          );

          contentList[i].style.top = minHeight + rowGap + "px";
          contentList[i].style.left = (contentWidth + columnGap) * index + "px";

          // 修改最小列的高度 最小列的高度 = 当前自己的高度 + 拼接过来的高度 + 行间距
          lineConentHeightList[index] += height + rowGap;
        }
      }
    },

    // 标题  全选
    handleCheckAllChange(val, dataTypeId,row){
      const ids = []
      row.chart.length && row.chart.forEach(element => {
        ids.push(element.id)
      })
      this.checkedBoardId[dataTypeId] = val ? ids : [] //全选
      this.state.isIndeterminate[dataTypeId] = false
      // this.check[dataTypeId] = row.chart 
      this.$emit('checked',this.checkedBoardId)
      sessionStorage.setItem('checkedBoardId',JSON.stringify(this.checkedBoardId))
      sessionStorage.setItem('checkAll',JSON.stringify(this.state.checkAll))
    },
    // 内容
    handleCheckedBoard(value, id, row){
      this.checkedBoardId[id] = value
      const checkedCount = value.length
      this.state.checkAll[id] = checkedCount === row.chart.length 
      this.state.isIndeterminate[id] = checkedCount > 0 && checkedCount < row.chart.length 
      // this.check[id] =  row.chart.filter(obj=>value.includes(obj.id))
      this.$emit('checked',this.checkedBoardId,this.state.checkAll)
      // this.$emit('caches',this.checkedBoardId,this.state.checkAll,this.state.isIndeterminate)
      sessionStorage.setItem('checkedBoardId',JSON.stringify(this.checkedBoardId))
      sessionStorage.setItem('isIndeterminate',JSON.stringify(this.state.isIndeterminate))
      sessionStorage.setItem('checkAll',JSON.stringify(this.state.checkAll))
    },
  },
  created() {
   
  },
};
</script>

<style lang="scss" scoped>

/* 最外层大盒子 */
.tab-container {
  position: relative;
}
/* 每个小盒子 */
.tab-container .tab-item {
  position: absolute;
  height: auto;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  background: white;
  /* 元素不能中断显示 */
  break-inside: avoid;
  text-align: center;
}


.sub-item{
	box-shadow: 0px 0px 4px #00000040;
	margin-right: 12px;
	margin-bottom: 10px;
}
.sub-item-title{
	display:flex;
	align-items: center;
	justify-content: space-between;
  padding: 0px 16px 0px 4px;
  border-bottom: 1px solid #dddddd;
  background-color: #F3F3F3;
}
.display_flex{
	display: flex;
}
.divider{
  border-left: 2px solid #cccccc;
  height: 16px;
  margin-right: 12px;
}
.display_flex span{
  font-size:15px;
  color: #002925;
  font-weight:600;
}
.sub-item-list{
	padding: 12px 16px; 
}
.sub-item-list .el-checkbox{
  display: flex;
  margin-right: 0;
  flex-direction: row-reverse;
  justify-content: space-between;
  color: #005F59;
  font-size: 14px;

}
:deep(.el-checkbox__input.is-indeterminate .el-checkbox__inner){
  background-color: #F9B707;
  border-color: #F9B707;
}

:deep(.el-checkbox__input.is-checked+.el-checkbox__label){
  color: #005F59 ;
}
:deep(.el-checkbox__input.is-checked .el-checkbox__inner){
  border-color:#F9B707;
  background-color: #F9B707;
}
:deep(.el-checkbox__inner){
  border: 1px solid #005F59;
}
:deep(.el-checkbox__inner:hover){
  border-color:#005F59;
  // border: 1px solid #005F59;
}
</style>
<!--派遣总台数-->
<template>
	<div class="tips" style="color: #fff;" v-if="option.yData==0" ></div>
	<div
		@click="sizechange"
		ref="MyChartDom"
		v-loading="loading"
		:style="{ width: '100%', height: '100%', overflow: 'hidden' }"
	></div>
	<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
</template>

<script>
import * as echarts from "echarts";
import { apiGetSenderStatus } from "@/api/ChartsDataRequest";

export default {
	name: "total_dispatch_income_hours",
	props: ["xref", "type", "chartData"],
	emits: ["closeChart"],
	data() {
		return {
			option: '',
			loading: true,
			isShow:''
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},
		// 图表创建
		createChart() {
			this.ChartDom = this.$refs.MyChartDom;
			this.MyChart = echarts.init(this.ChartDom, null);
			apiGetSenderStatus().then((res) => {
				
				if (res?.data?.code == 200) {
					const currentData = res?.data?.data;
					this.option = currentData
					const tData = currentData.xData.reduce(
						(prev, cur) => prev.concat(cur),
						[]
					);

					let xData1 = []
					let xData2 = []
					let xData3 = []
					if(currentData.yData.length==0){
						xData1 = []
						xData2 = []
						xData3 = []
					}else{
							xData1 = currentData.yData.find(
							(item) => item.name === "派遣总台数"
						).data;
							xData2 = currentData.yData.find(
								(item) => item.name === "派遣累计收入"
							).data;
							xData3 = currentData.yData.find(
								(item) => item.name === "总工时"
							).data;
					}
					this.MyChart.setOption({
						title: {
							text: "派遣总台数、派遣累计收入、总工时",
							left: "center",
							top: "2%",
							textStyle: {
								color: "#fff",
								fontWeight: "normal",
								fontSize: "18px",
							},
						},
						tooltip: {
							trigger: "axis",
							axisPointer: {
								type: "cross",
								label: {
									backgroundColor: "#6a7985",
								},
							},
							textStyle: {
								align: "left",
							},
							formatter: function(params) {
								let relVal = params[0].name;
								for (let i = 0; i < params.length; i++) {
									if(params[i].seriesName === '派遣总台数'){
										relVal +=
										'<div style="width:180px"><span style="display: inline-block;width: 5px;border: 2px solid #bfdeff;margin-bottom:4px;margin-right:5px"></span>' +
										params[i].seriesName +
										'<span style="text-align:right;float:right">' +
										params[i].value +
										"</span>" +
										"</div>";
									}else if(params[i].seriesName === '派遣累计收入'){
										relVal +=
										'<div style="width:180px"><span style="display: inline-block;width:5px;border: 2px solid #f9b707;margin-bottom:4px;margin-right:5px"></span>' +
										params[i].seriesName +
										'<span style="text-align:right;float:right;">' +
										params[i].value +
										"</span>" +
										"</div>";
									}else {
										relVal +=
										'<div style="width:180px"><span style="display: inline-block;width: 5px;border: 2px dashed #f26361;margin-bottom:4px;margin-right:5px"></span>' +
										params[i].seriesName +
										'<span style="text-align:right;float:right">' +
											Math.round(params[i].value) +'h'+
										"</span>" +
										"</div>";
									}
								}
								return relVal;
							}
						},
						legend: {
							bottom: "20",
							textStyle: {
								color: "#fff",
								fontSize: "14px",
								padding: [0, 0, 0, 0],
							},
							data: [
								{
									icon: "path://M30.6983 4.13281H0.164062V0.132812H30.6983V4.13281Z",
									name: "派遣总台数",
								},
								{
									icon: "path://M30.6983 4.13281H0.164062V0.132812H30.6983V4.13281Z",

									name: "派遣累计收入",
								},
								{
									icon: "path://M4.52772 4.13281H0.210938V0.132812H3.52772V4.13281ZM16.7948 4.13281H10.1613V0.132812H16.7948V4.13281ZM26.7452 4.13281H23.4284V0.132812H26.7452V4.13281Z",
									name: "总工时",
								},
							],
						},
						grid: {
							top: "15%",
							left: "130",
							right: "70",
							bottom: "80",
						},
						xAxis: {
							type: "category",
							boundaryGap: false,
							axisLabel: {
								color: "#fff", // 更改坐标轴文字颜色
								fontSize: 14, // 更改坐标轴文字大小
							},
							data: tData,
						},
						yAxis: [
							// 派遣总台数
							{
								type: "value",
								position: "left",
								splitLine: {
									//Y轴线的颜色
									show: true,
									interval: "auto",
									lineStyle: {
										color: "#546f6d",
									},
								},
								axisTick: {
									show: false,
								},
								//y轴左侧文字颜色
								axisLine: {
									onZero: true,//表示 y轴线是否在 0 刻度上。
									lineStyle: {
										color: "#fff",
									},
								},
								// y轴左侧文字大小
								axisLabel: {
									margin: 10,
									textStyle: {
										fontSize: 12,
									},
								},
							},
							{
								// 累计收入,
								type: "value",
								position: "right",
								// 设置刻度线的颜色等样式
								axisLine: {
									onZero: true,
									lineStyle: {
										color: "rgb(249, 183, 7)",
										// width: 3
									},
								},
								splitLine: {
									show: false, //想要不显示网格线，改为false
								},
							},
							{//总工时
								position: "left",
								offset: 60,
								axisLine: {
									onZero: true,
									lineStyle: {
										color: "#f26361",
										// width: 3
									},
								},
								splitLine: {
									show: false, //想要不显示网格线，改为false
								},
							},

						],
						series: [
							{
								name: "派遣总台数",
								type: "line",
								stack: "Total1",
								yAxisIndex: 0,
								symbol: "none",
								smooth: true, //是否平滑曲线显示
								lineStyle: {
									color: "#cfe4fc",
									width: 3,
									// type: 'dotted'
								},
								itemStyle: {
									color: "#cfe4fc",
								},
								data: xData1,
							},
							{
								name: "派遣累计收入",
								type: "line",
								stack: "Total2",
								// symbolSize: 8,
								// symbol: "diamond",
								// smooth: true, //是否平滑曲线显示
								yAxisIndex: 1,
								lineStyle: {
									color: "#f9b707",
									width: 3,
								},
								itemStyle: {
									//累计收入
									normal: {
										color: "#f9b707",
									},
								},
								data:xData2,
							},
							{
								name: "总工时",
								type: "line",
								stack: "Total",
								yAxisIndex: 2,
								// symbol: "none",
								// symbolSize: 8,//标记的大小
								lineStyle: {
									color: "#f26361",
									width: 3,
									type: "dashed",
								},
								itemStyle: {
									normal: {
										color: "#f26361",
									},
								},
								data:xData3 ,
							},
						],
					});
				}
				this.loading = false;
			
			});

			setTimeout(() => {
				this.$nextTick(() => {
					this.MyChart.resize();
				});
			}, 0);
		},
		sizechange() {
			this.MyChart.resize();
		},
	},
	mounted() {
		this.createChart();
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style>
.vue-chart-setting {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 24px;
	background: url("../../assets/settings.svg");
	background-size: 16px 16px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}

.vue-chart-close {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 2px;
	right: 2px;
	background: url("../../assets/x.svg");
	background-size: 18px 18px;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	background-origin: content-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	cursor: pointer;
}
</style>

/**
 * 争对笔记本125% 150%等等的缩放比例导致echart的错位解决方案
 * @id 父容器id
 * @returns
 */
export const detectZoom = (id = 'chartWrap') => {
  // console.log(window.devicePixelRatio, 'window.devicePixelRatio')
  if (window.devicePixelRatio >= 2) {
    return
  }
  let ratio = 0
  const screen = window.screen
  const ua = navigator.userAgent.toLowerCase()
  if (window.devicePixelRatio !== undefined) {
    ratio = window.devicePixelRatio
  } else if (~ua.indexOf('msie')) {
    if (screen.deviceXDPI && screen.logicalXDPI) {
      ratio = screen.deviceXDPI / screen.logicalXDPI
    }
  } else if (
    window.outerWidth !== undefined &&
    window.innerWidth !== undefined
  ) {
    ratio = window.outerWidth / window.innerWidth
  }
  if (ratio) {
    ratio = Math.round(ratio * 100)
  }
  // const m = detectZoom()

  // document.getElementById(id).style.zoom = 100 / Number(ratio)

  let size = window.screen.height / 1080
  document.getElementById(id).style.zoom = size
  document.styleSheets[document.styleSheets.length - 1].insertRule('canvas {zoom: ' + 1 / size + '}');
  document.styleSheets[document.styleSheets.length - 1].insertRule('canvas {transform: scale(' + size + ')}');
  document.styleSheets[document.styleSheets.length - 1].insertRule('canvas {transform-origin: 0 0}');
  // return ratio
}


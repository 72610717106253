<!--当前部署机器人数量对比-->
<template>
	<div class="chart-drobot-title">当前部署机器人数对比</div>
	<div class="robot-data">
		<img src="../../assets/robot-data-icon.png" />
		<div class="right-text">
			<div class="text-item">
				<div>{{ currentDeployed }}</div>
				<div>当前部署机器人数</div>
			</div>
		</div>
	</div>
	<div class="robot-data-bot">
		<div class="text-item">
			<div>{{ monthAdded }}</div>
			<div class="son-title">本月新增</div>
		</div>
		<div class="text-item">
			<div>{{ weekAdded }}</div>
			<div class="son-title">本周新增</div>
		</div>
		<span v-show="isShow!=='1'" class="vue-chart-close" @click="handleCloseChart"> </span>
	</div>
</template>
<script>
import { apiGetRobotDeployedNum } from "@/api/ChartsDataRequest";
export default {
	name: "terminated_customer",
	props: [],
	emits: ["closeChart"],
	data() {
		return {
			currentDeployed: 0,
			monthAdded: 0,
			weekAdded: 0,
			loading: true,
			isShow:''
		};
	},
	methods: {
		// 图表关闭
		handleCloseChart() {
			this.$emit("closeChart");
		},

		// 图表创建
		createChart() {
			this.loading = false;
			apiGetRobotDeployedNum().then((res) => {
				if (res.data.code == 200) {
					const currentData = res?.data?.data || {};
					this.currentDeployed = currentData.current_total || 0;
					this.monthAdded = currentData.month_add || 0;
					this.weekAdded = currentData.week_add || 0;
				} 
				this.loading = false;
			});
		},
	},
	mounted() {
		this.createChart();
		this.isShow= sessionStorage.getItem("isShow")
	},
};
</script>

<style lang="scss" scoped>
.chart-drobot-title {
	color: #fff;
	font-size: 18px;
	padding-top: 8px;
}
.son-title {
	font-size: 14px;
}
.robot-data {
	display: flex;
	align-items: center;
	justify-content: center;

	height: calc(100% - 120px);
	width: 100%;
	position: relative;
	&::after {
		content: "";
		display: block;
		width: 80%;
		height: 2px;
		background-color: #69827e;
		position: absolute;
		bottom: -10px;
	}
	img {
		display: block;
		width: calc((100% / -50) * 100);
		height: calc((100% / 110) * 100);
		object-fit: contain;
	}

	.right-text {
		color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 15px;
		.text-item {
			text-align: center;
			font-size: 14px;
			div:first-child {
				font-size: 64px;
				color: #fff;
			}
			div:last-child {
				font-size: 20px;
				color: #f9b707;
				margin-top: 15px;
			}
		}
	}
}
.robot-data-bot {
	display: flex;
	padding: 20px 0;
	.text-item {
		flex: 1;
		text-align: center;
		font-size: 12px;
		color: #fff;
		div:first-child {
			font-size: 24px;

			color: #f9b707;
		}
	}
}
</style>
